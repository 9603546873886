import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as IMG_CONST from "./assets";
import { PaidApplicationIcon, RejectedIcon, enrolledImg, TotalApplicationIcon, countryImage1, countryImage2, countryImage3, countryImage4, countryImage5, countryImage6 } from "./assets";
export interface SelectEventType {
  target: {
      value: string | unknown;
  }
};
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";

export const configJSON = require("./config");

export interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  }
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  message: string;
  data: StaffMember[];
  meta: {
    message: string;
  };
}

export interface StaffMember {
  id: string;
  type: string;
  attributes: {
    id: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    phone_number?: string;
    status?: string;
    name?: string;
    file_type?: string | null;
    document_category?: string;
    account_id?: number;
    created_at?: string;
    updated_at?: string;
    url?: URL;
  };
}

export interface TeamPerformance {
  id: string;
  type: string;
  attributes: {
    name: string;
    assigned_student: string;
    active_applications: string;
    tuition_fees_paid: string;
    conversion: string;
  }
}

export interface TeamPerformanceData {
  data: TeamPerformance[];
}

export interface PendigTask {
  id:string;
  title: string;
  student: string;
  university: string;
  dueDate: string;
  daysLeft:string;
}

export interface OffersData {
  data: Offers[];
}

export interface Offers {
  id: string ;
  attributes: {
    university: string;
    id:string;
    description: string;
    validity_period: string;
    universityDescription: string;
    feeWaived:string;
    eligibleCountries: string;
    terms_and_conditions: string;
    title:string;
    promo_code : string;
    discount_value : string;
  }
}
export interface IntakeList {
  month_group: string;
  universities: {
    logo_url: string,
    name: string,
  }[];
}

export interface StatusCountType{
    total_applications: number;
    paid_applications: number;
    rejected_applications: number;
    enrolled: number;
    students: number;
}
export interface RecentUpdateData {
  data:RecentUpdate[]
}
export interface RecentUpdate {
  id: string;
  type: string;
  attributes: {
    announcement_type: string;
    title: string;
    content: string;
    published_at: string;
    image: string;
    country: [];
  }
}

export interface OtherService {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
  }
}

export interface OtherServiceData {
  data: OtherService[];
}
export interface Salemanager {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    image: string | null;
  }
}

export interface SalemanagerData {
  data: Salemanager[];
}

export interface UniversityResponse {
  country: []
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  toggleDrawer:boolean;
  countryValue: string | unknown;
  userDetails: UserDetails;
  teamPerformanceTable: Array<TeamPerformance>;
  pendigTaskData: Array<PendigTask>;
  offersData: Array<Offers>;
  offerPaginationNumber:number;
  statusCountData: StatusCountType;
  value: number;
  isDisable: boolean;
  isDisableUpdate:boolean;
  intakeList: Array<IntakeList>;
  recentUpdate: Array<RecentUpdate>;
  otherService: Array<OtherService>
  recentPaginationNumber: number;
  agentRecords: Array<Salemanager>;
  countryIntakeData: Array<string>;
  countryIntakeValue: string | unknown;
  openTooltipId: null | string;
  updateModalOpen:boolean;
  updateValue:string;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getProfileAPICallId: string = "";
  getStatusAPICallId: string =  "";
  getTeamPerformanceCallId: string = "";
  recentUpdateCallId: string = "";
  offerCallId: string = "";
  otherServiceCallId: string = "";
  salesManagerCallId: string = "";
  intakeCallId: string = "";
  countryCallId: string = "";
  seed:number = 12345;
  countryData = [
    {
      name:"CAD",
      collegeName:"Durham College",
      image:countryImage1,
      value:"4000",
    },
    {
      name:"AUD",
      collegeName:"Fleming College",
      image:countryImage6,
      value:"2000",
    },
    {
      name:"USD",
      collegeName:"Niagara College",
      image:countryImage2,
      value:"1000",
    },
    {
      name:"GBP",
      collegeName:"Algonquin College",
      image:countryImage3,
      value:"1000",
    },
    {
      name:"EUR",
      collegeName:"Fanshawe College",
      image:countryImage4,
      value:"1000",
    },
    {
      name:"NZD",
      collegeName:"Saskatchewan Polytechnic",
      image:countryImage5,
      value:"1000",
    },
    
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      toggleDrawer: false,
      countryValue:"none",
      openTooltipId: null,
      updateModalOpen:false,
      statusCountData: {
        total_applications: 0,
        paid_applications: 0,
        rejected_applications: 0,
        enrolled: 0,
        students: 0
      },
      userDetails: {} as UserDetails,
      updateValue:"",
      pendigTaskData: [
        {
          id: "374773755",
          title: "Accept application",
          student: "Dinesh Anand",
          university: "Algoma University - Brampton",
          dueDate: "01-Nov-2023",
          daysLeft: "1 day left"
        },
        {
          id: "152266363",
          title: "Accept application",
          student: "Fernando Anandanon",
          university: "Algoma University - Brampton",
          dueDate: "01-Nov-2023",
          daysLeft: "1 day left"
        },
        {
          id: "374773755",
          title: "Accept application",
          student: "Dinesh Anand",
          university: "Algoma University - Brampton",
          dueDate: "01-Nov-2023",
          daysLeft: "1 day left"
        }
      ],
      teamPerformanceTable: [],
      offersData:[],
      offerPaginationNumber:0,
      recentPaginationNumber:0,
      agentRecords:[],
      value: 0,
      isDisable: false,
      isDisableUpdate:false,
      intakeList : [],
      recentUpdate: [],
      otherService: [],
      countryIntakeData: [],
      countryIntakeValue: "none"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getFormProfileAccount();
    this.getStatusCount();
    this.getTeamPerformance();
    this.getRecentUpdate();
    this.getOfferApi();
    this.getOtherServiceApi();
    this.getSalesManagerInfoApi();
    this.getIntakeApi();
    this.getCountryApi();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName:string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigationToAnyPage = (pageName:string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  countryChange = (event: SelectEventType) => {
    this.setState({ countryValue: event.target.value });
  };

  countryIntakeChange = (event: SelectEventType) => {
    this.setState({ countryIntakeValue: event.target.value }, () => { this.getIntakeApi() });
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType & UserDetailsResponse & StatusCountType & TeamPerformanceData & RecentUpdateData & OffersData & OtherServiceData & SalemanagerData & IntakeList[] & UniversityResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.profilePicSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getStatusAPICallId) {
      this.statusCountSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTeamPerformanceCallId) {
      this.teamDataSucessCallBack(responseJson);
    } 
    if (apiRequestCallId === this.recentUpdateCallId) {
      this.recentUpdateSucessCall(responseJson);
    }
    if (apiRequestCallId === this.offerCallId) {
      this.offerSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.otherServiceCallId) {
      this.otherServiceSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.salesManagerCallId) {
      this.saleManagerSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.intakeCallId) {
      this.intakeSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.countryCallId) {
      this.countrySucessCallBack(responseJson);
    }
  };

  profilePicSucessCallBack = (responseJson:UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  statusCountSucessCallBack = (responseJson:StatusCountType) => {
    this.setState({ statusCountData: responseJson });
  };

  teamDataSucessCallBack = (responseJson:TeamPerformanceData)=>{
    this.setState({ teamPerformanceTable: responseJson.data});
  };

  recentUpdateSucessCall = (responseJson:RecentUpdateData)=>{
    this.setState({ recentUpdate: responseJson.data});
  };

  offerSucessCallBack = (responseJson:OffersData) => {
    this.setState({ offersData: responseJson.data });
  };

  otherServiceSucessCallBack = (responseJson:OtherServiceData)=>{
    this.setState({ otherService: responseJson.data});
  };

  saleManagerSucessCallBack = (responseJson: SalemanagerData) => {
    this.setState({ agentRecords: responseJson.data });
  };

  intakeSucessCallBack = (responseJson: IntakeList[]) => {
    this.setState({ intakeList: responseJson });
  };

  countrySucessCallBack = (responseJson: UniversityResponse) => {
    this.setState({ countryIntakeData: responseJson.country });
  };

  isValidResponse = (responseJson: ValidResponseType ) => {
    return (
      (responseJson) 
    );
  };

  getFormProfileAccount = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  };

  getStatusCount = async () => {
    this.getStatusAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getStatusEndPoint
    });
  };

  getTeamPerformance = async () => {
    this.getTeamPerformanceCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getTeamDataEndPoint
    });
  };

  getRecentUpdate = async () => {
    this.recentUpdateCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getrecentUpdateEndPoint
    });
  };

  getOfferApi = async () => {
    this.offerCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getOfferEndPoint
    });
  };

  getOtherServiceApi = async () => {
    this.otherServiceCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getOtherServiceEndPoint
    });
  };

  getSalesManagerInfoApi = async () => {
    this.salesManagerCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getSalesManagerEndPoint
    });
  };

  getIntakeApi = async () => {
    let universityFilter = "" as unknown;
    if (this.state.countryIntakeValue !== "none") {
      universityFilter = this.state.countryIntakeValue;
    }
    this.intakeCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: `${configJSON.inTakesApiEndpoint}?country=${universityFilter}`
    });
  };

  getCountryApi = async () => {
    this.countryCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.countryEndPoint
    });
  };

  onNextOffer = () => {
    this.setState({ offerPaginationNumber: this.state.offerPaginationNumber + 1 } ,() => {
    this.nextBtnDisable();
    });
  };

  onPrevOffer = () => {
    this.setState({ isDisable: false, offerPaginationNumber: this.state.offerPaginationNumber - 1 })
  };

  onNextUpdate = () => {
    this.setState({ recentPaginationNumber: this.state.recentPaginationNumber + 1 } ,() => {
      this.nextUpdateBtnDisable();
    });
  };

  onPrevUpdate = () => {
    this.setState({ isDisableUpdate: false, recentPaginationNumber: this.state.recentPaginationNumber - 1 })
  };

  nextUpdateBtnDisable = () => {
    if(this.state.recentPaginationNumber === this.state.recentUpdate.length - 1){
        this.setState({
          isDisableUpdate: true
        })
    }
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  nextBtnDisable = () => {
    if(this.state.offerPaginationNumber === this.state.offersData.length - 1){
        this.setState({
          isDisable: true
        })
    }
  };

  navigationToOfferPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DashboardOffer"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToAddApplicationPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddApplication"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToNewStudentPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "FormApprovalWorkflow"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToOtherService = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OtherServices"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  
  onRecentNextDisable = () => {
    return this.state.isDisable || this.state.offersData.length === 1 || this.state.offersData.length === 0
  };

  onOfferNextDisable = () => {
    return this.state.isDisableUpdate || this.state.recentUpdate.length === 1
  };

  handleTooltipOpen = (idValue: string) => {
    this.setState({
      openTooltipId: idValue
    });
  };

  handleTooltipClose = () => {
    this.setState({
      openTooltipId: null
    });
  };

  handleOpenClose = (value: RecentUpdate | null = null) => {
    this.setState({
      updateModalOpen: !this.state.updateModalOpen,
      updateValue: value?.attributes?.content || this.state.updateValue,
    });
  };
  DashbordViewMore = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "DasbordViewMore");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  // Customizable Area End
}

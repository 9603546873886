import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  styled
} from "@material-ui/core";
import React, { Component } from "react"
import * as IMG_CONST from "./assets";
import MenuIcon from "@material-ui/icons/Menu";
import { Close } from "@material-ui/icons";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { getStorageData, setStorageData } from "../../../packages/framework/src/Utilities";
type MyProps = {
  isLogin: boolean;
  onHomeClick: (pageName: string) => void;
  onSignUpClick?: () => void;
  onLoginClick?: () => void;
  handleToggle?: () => void;
  toggleData?: boolean;
  isUserLogin: boolean;
  userName?: string;
  role?: string;
  profileImg?: { 
    url: string 
  };
}

interface StateType {
  activePage:string;
}

export default class AppHeader extends Component<MyProps,StateType> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      activePage:"RecruitmentPartner"
    };
  }

  renderScreenInLandingPage = (value:string) => {
    if(value && value !== ""){
      this.setState({
        activePage: value,
      });
    }
  }

  async componentDidMount() {
    const getLandingActivePage = await getStorageData("LandingPageActive")
      this.renderScreenInLandingPage(getLandingActivePage)
  }

  clickOnHomeClick = (pageName:string) => {
    this.props.onHomeClick(pageName);
    this.renderScreenInLandingPage(pageName)
  };

  clickOnLogin = () => {
    if(this.props.onLoginClick){
      this.props.onLoginClick();
      setStorageData("LandingPageActive","notActive")
    }
  };

  clickOnRegister = () => {
    if(this.props.onSignUpClick){
    this.props.onSignUpClick();
    setStorageData("LandingPageActive","notActive")
  }
  };

  render() {
    return (
      <>
        <NavbarBox>
          <TopImg src={IMG_CONST.logoImg}/>
          {this.props.isUserLogin ? this.renderProfile() : <BtnBox >
                        <NavBarButton onClick={() => this.clickOnHomeClick("RecruitmentPartner")}
            style={{color:this.state.activePage=== "RecruitmentPartner"?"#BB393D":"black"}}>
              Recruitment Partner
            </NavBarButton>
            <NavBarButton onClick={() => this.clickOnHomeClick("school")}
            style={{color:this.state.activePage=== "school"?"#BB393D":"black"}}>School</NavBarButton>
          </BtnBox>
          }
          {this.props.isLogin && <BtnBox>
            <LoginBtn onClick={this.clickOnLogin}>Login</LoginBtn>
            <SingupBtn onClick={this.clickOnRegister}>Register</SingupBtn>
          </BtnBox>}
          <NavbarApp>
            <AppBar position="static">
              <Toolbar>
                <IconButton edge="end" color="inherit" aria-label="menu">
                  <MenuIcon className="menuItem" onClick={this.props.handleToggle} />
                </IconButton>
              </Toolbar>
            </AppBar>
          </NavbarApp>
        </NavbarBox>
        {this.renderLogin()}
      </>
    )
  }

  onNavigate = () => {
    window.location.href = "CustomisableUserProfiles"
  }

  renderProfile = () => {
    return (
      <ProfileBox 
        onClick={() => this.onNavigate()}
      >
        <NotificationsIcon className="notifIcon" />
        <Box className="profileImageBox">
          {
            this?.props?.profileImg ? <img
              src={this?.props?.profileImg.url}
              alt="Preview"
              className="profileImage"
            ></img> :
              <AccountCircleIcon className="profileImage" />
          }

        </Box>
        <Box className="profileName">
          <Typography className="name">{this.props.userName}</Typography>
          <Typography className="role">{this.props.role}</Typography>
        </Box>
      </ProfileBox>
    )
  };

  renderProfileDrawer = () => {
    return (
      <ProfileBoxDrawer 
      onClick={() => this.onNavigate()}
      >
        <NotificationsIcon className="notifIcon" />
        <Box className="profileImageBox">
          {
            this?.props?.profileImg ? <img
              src={this?.props?.profileImg.url}
              alt="Preview"
              className="profileImage"
            ></img> :
              <AccountCircleIcon className="profileImage" />
          }
        </Box>
        <Box className="profileName">
          <Typography className="name">{this.props.userName}</Typography>
          <Typography className="role">{this.props.role}</Typography>
        </Box>
      </ProfileBoxDrawer>
    )
  };

  renderLogin() {
    return (
      <CustomDrawer anchor={"right"} open={this.props.toggleData} onClose={this.props.handleToggle}>
        <CloseIcon onClick={this.props.handleToggle} />
        <HeaderTop>
{this.props.isUserLogin ? this.renderProfileDrawer() : <BtnNewBox>
            <NavBarButton onClick={() => {this.clickOnHomeClick("RecruitmentPartner");this.props.handleToggle && this.props.handleToggle();}} 
            style={{color:this.state.activePage=== "RecruitmentPartner"?"#BB393D":"black"}}>
              Recruitment Partner
            </NavBarButton>
            <NavBarButton onClick={() => {this.clickOnHomeClick("school");this.props.handleToggle && this.props.handleToggle()}}
            style={{color:this.state.activePage=== "school"?"#BB393D":"black"}}>School</NavBarButton>
          </BtnNewBox>
          }

          {this.props.isLogin && <BtnNewBoxLoginBox>
            <LoginBtn onClick={this.clickOnLogin}>Login</LoginBtn>
            <SingupBtn onClick={this.clickOnRegister}>Register</SingupBtn>
          </BtnNewBoxLoginBox>}
        </HeaderTop>
      </CustomDrawer>
    )
  };
}


const NavbarBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 40px",
  borderBottom: "1px solid #E2E8F0",
  position: "sticky",
  width:"100%",
  boxSizing:"border-box",
  top: 0,
  backgroundColor: "#ffffff",
  zIndex: 1000,
  "& .hamBurger": {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  }
}));

const TopImg = styled("img")(({ theme }) => ({
  width: "211px",
  cursor: "pointer",
  height: "40px",
  [theme.breakpoints.down("xs")]: {
    width: "150px"
  }
}));


const BtnBox = styled(Box)(({ }) => ({
  display: "flex",
  gap: "40px",
  "@media(max-width: 992px)": {
    display: "none"
  }
}));

const LoginBtn = styled(Button)({
  border: "2px solid #BB393D",
  height: "40px",
  fontWeight: 600,
  borderRadius: "8px",
  color: "#BB393D",
  padding: "10px 16px",
  textTransform: "unset",
});

const SingupBtn = styled(Button)({
  backgroundColor: "#BB393D",
  color: "#fff",
  height: "40px",
  fontWeight: 600,
  borderRadius: "8px",
  padding: "10px 16px",
  textTransform: "unset",
  "&.MuiButton-root:hover": {
    backgroundColor: "#BB393D"
  }
});

const NavBarButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  cursor: "pointer",
  fontSize: "14px !important",
  textTransform: "capitalize"
}));

const NavbarApp = styled(Box)({
  display: "none",
  "@media(max-width: 992px)": {
    display: "block"
  },
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff",
    boxShadow: "none"
  },
  "& .menuItem": {
    color: "#B6373C"
  }
});

const NavTopBar = styled(Box)({
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const HeaderTop = styled(Box)({
  "media(max-width: 992px)": {
    display: "flex",
    flexDirection: "column"
  }
});

const CustomDrawer = styled(Drawer)({
  padding: "0px 40px",
});

const BtnNewBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  width: "200px",
  padding: "0px 20px",
  height: "100px",
  justifyContent: "space-between"
});

const BtnNewBoxLoginBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  width: "200px",
  padding: "0px 20px",
  height: "90px",
  justifyContent: "space-between"
});

const CloseIcon = styled(Close)({
  margin: "20px",
  cursor: "pointer"
});

const ProfileBox = styled(Box)({
  "@media(max-width: 992px)": {
    display: "none"
  },
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "10px",

  "& .profileImageBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "44px",
    height: "44px",
    cursor: "pointer"
  },
  "& .profileImage": {
    borderRadius: "50%",
    height: "30px",
    width: "30px"
  },
  "& .notifIcon": {
    cursor: "pointer"
  },
  "& .profileName": {
    cursor: "pointer"
  },
  "& .name": {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "Plus Jakarta Sans",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .role": {
    lineHeight: "16px",
    fontSize: "13px",
    fontWeight: 400,
    fontStyle: "Plus Jakarta Sans",
    color: "rgba(33, 33, 33, 1)"
  }
})

const ProfileBoxDrawer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "10px",
  padding: "0px 20px",

  "& .profileImageBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "44px",
    height: "44px",
    cursor: "pointer"
  },
  "& .profileImage": {
    borderRadius: "50%",
    height: "30px",
    width: "30px"
  },
  "& .notifIcon": {
    cursor: "pointer"
  },
  "& .profileName": {
    cursor: "pointer"
  },
  "& .name": {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: 500,
    fontStyle: "Plus Jakarta Sans",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .role": {
    lineHeight: "16px",
    fontSize: "13px",
    fontWeight: 400,
    fontStyle: "Plus Jakarta Sans",
    color: "rgba(33, 33, 33, 1)"
  },
})
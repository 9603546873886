import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import DasbordViewMoreController from "./DasbordViewMoreController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import { Backdrop, Card, Grid, TextField, Typography } from "@material-ui/core";
export const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End

export default class DasbordViewMore extends DasbordViewMoreController {
  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DasbordViewMoreStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />

          <Box className="mainBar">
            <BoxButton>
              <Box><StudentTxt >{configJSON.recentUpdateTitle}</StudentTxt></Box>
              <Box className="datePickers">
                <TextField
                  data-test-id="startDateId"
                  value={this.state.stateDate}
                  onChange={this.handleStartDateChange}
                  className="DatePicker"
                  variant="outlined"
                  size="small"
                  type="date"
                  placeholder={configJSON.selectStudentAddedByPlaceholder}
                  InputProps={{
                    inputProps: {max: this.state.todayDate },
                  }}
                />
                <TextField
                  data-test-id="endDateId"
                  value={this.state.endDate}
                  disabled={!this.state.stateDate}
                  onChange={this.handleEndDateChange}
                  className="DatePicker"
                  variant="outlined"
                  size="small"
                  type="date"
                  placeholder={configJSON.selectStudentAddedByPlaceholder}
                  InputProps={{
                    inputProps: { min: this.state.stateDate, max: this.state.todayDate },
                  }}
                />
              </Box>
            </BoxButton>
            <BoxButton>
              <Box className="BoxContainer">
                {
                  this.paginateData(this.state.CardDetailsData, this.state.page, 4).map((item, index) => {
                    return (
                      <Card className="CardContainer" data-testid={`UpadtedListItem` + index} key={index + "12"}>
                        <Grid container>
                          <Grid item xs={12}
                            md={3}
                            className="ImageContainer">
                            <Box>
                              <img src={item.imageUrl} className="CardImage" />
                            </Box>
                          </Grid>
                          <Grid item md={9} xs={12}>
                            <div className="ContentContainer">
                              <Grid container className="ReadMoreContainer">
                                <Grid xs={4}>
                                  <Button className="readMoreBtn" onClick={() => {
                                    this.handleOpenClose(item.points)
                                  }} data-test-id={`readMoreId${index}`}>{configJSON.readmoreTxt}</Button>
                                </Grid>
                                <Grid xs={8}>
                                  <Typography className="AnnouncementType">{item.AnnouncementType}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid xs={12}>
                                  <Box className="ApplicableCountryContainer">
                                    <div>
                                      <span className="ApplicableCountryClass">
                                        {configJSON.applicableCountriesText}
                                      </span>
                                      <span>
                                        {Array.isArray(item.ContryName) && item.ContryName.length !== 0 && item.ContryName.join(", ")}
                                      </span>
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className="HeadingContainer">
                                <Typography variant="h5" className="Heading defaultTxtBold">{item.heading}</Typography>
                                <Typography variant="subtitle1" data-test-id={`postedOnId${index}`}>{configJSON.postedOnText}{item.PublishDate}</Typography>
                              </div>
                              <div className="EditorContent">
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  dangerouslySetInnerHTML={this.SetInnteHtml(item.points)}
                                  color="textSecondary" />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  })
                }
                <Box className="PaginationContainer" >
                  <Pagination
                    data-test-id="countTestId"
                    count={this.renderCount()}
                    page={this.state.page}
                    onChange={this.handleChangePage}
                  />
                </Box>
              </Box>
            </BoxButton>
          </Box>
        </LeadBox>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.updateModalOpen}
        onClose={()=>this.handleOpenClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.updateModalOpen}>
          <InnerModalBox>
            <Box
              className="modalTextBox"
            >
              <IconButton
                onClick={() => this.handleOpenClose()}
                className="iconCloseBtn"
                data-test-id="modalTestId"
              >
                <CloseIcon
                  aria-label="close"
                />
              </IconButton>
              <Typography
                className="modalItemTxt"
                data-test-id="renderedModalId"
                dangerouslySetInnerHTML={{ __html: this.state.updateValue }}
              />
            </Box>

          </InnerModalBox>
        </Fade>
      </Modal>
      </DasbordViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const DasbordViewMoreStyle = styled(Box)({
  "& .BoxContainer": {
    width: "100%"
  },
  "& .CardContainer": {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    padding: "20px 5px",
    marginTop: "10px",
    "@media only screen and (max-width: 425px) ": {
      padding: "0 5px"
    },
  },
  "& .CardImage": {
    width: "150px",
    borderRadius: "200px",
    height: "150px",
    maxHeight: '200px'
  },
  "& .ImageContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .ContentContainer": {
    padding: "10px 0px",
    paddingRight: "20px",
    "& .HeadingContainer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
      marginTop: "10px",
      "& .Heading": {
        color: "#400a9d",
      },
      "@media only screen and (max-width: 600px) ": {
        display: "block",
        padding: "0 20px"
      },
    },
    "& .EditorContent": {
      maxHeight: "200px",
      overflowY: "auto"
    },
    "& .ApplicableCountryContainer": {
      display: "flex",
      "& .ApplicableCountryClass": {
        fontWeight: 600,
        marginBottom: "4px",
        marginTop: "4px"
      }
    },
    "& .ReadMoreContainer": {
      margin: "5px 0",
      "& .readMoreBtn": {
        fontFamily: "Plus Jakarta Sans",
        color: "#1976d2",
        textTransform: "unset",
        padding: "0px !important",
        fontSize: "16px",
        fontWeight: "normal"
      },
      "& .AnnouncementType": {
        fontWeight: "700"
      }
    }
  },
  "& .PaginationContainer": {
    display: "flex",
    justifyContent: "center",
  }
})

const InnerModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .iconCloseBtn":{
    position: "absolute",
     top: 0, 
     right: 0 
  },
  "& .modalTextBox":{
    backgroundColor: "#FFFF",
    position: "relative",
    borderRadius: "5px",
    padding: "30px 60px",
    cursor: "pointer",
    "@media (max-width: 600px)": { 
      padding: "20px 30px",
    },
  },
  "& .modalItemTxt": {  
    maxHeight: "500px",
    overflowY: "scroll",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    }
  }
});

const BoxButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "10px"
  }
})

const StudentTxt = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px !important",
  fontWeight: '700 !important'
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    marginLeft: "64px",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
    "& .datePickers": {
      display: "flex",
      gap: "10px"
    }
  },
  "& .defaultTxtBold": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  }
});
// Customizable Area End
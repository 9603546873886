// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip"


exports.placeHolderEmail = "Email";
exports.gender = "Gender";
exports.country = "Country";
exports.maritalStatus ="Marital Status";
exports.back = "Back";
exports.next = "Next";
exports.email = "E-mail";
exports.mobile = "Mobile";
exports.emailPlaceholderTxt = "Enter mail id";
exports.mobilePlaceholderTxt = "Enter mobile number";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.emailPlaceHolder = "Enter Email ID"

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.chooseFile = "Choose a file to upload"
exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy Policy";
exports.btnTextSignUp = "Sign Up";
exports.addStudent = "Add Student"
exports.errorPasswordNotValid = "Password not valid.";
exports.date = "Date"
exports.remarks = "Remarks"
exports.remarksPlaceHolder = "Write something here..."
exports.errorTitle = "Error";
exports.realTimeTrackingTxt = "Want to send real time tracking status notification to student?"
exports.addDocuments = "Add Other Documents"
exports.contactPerson = "Contact Person"
exports.emergencyNamePlaceHolder = "Enter Emergency Person Name"
exports.visaRefusal = "Add Visa Refusal"
exports.emergencyContactsHeader = "Emergency Contact & Other Documents"
exports.travelHistoryTxt = "Add Travel History"
exports.errorBothPasswordsNotSame = "Passwords Should Be Same.";
exports.errorAllFieldsAreMandatory = "All Fields are Mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email Not Valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.educationDetail = "Education Detail"
exports.englishTestScore = "English Test Score"
exports.workExperience = "Work Experience"
exports.passportHistory = "Passport & Travel History"
exports.or = "or"
exports.dragFile = "Drag and drop files"
exports.acceptedFileFormatText = "Accepted file format: "
exports.acceptedFileSizeText = "Accepted max file size: "
exports.aceeptFileSize = "10MB"
exports.fileType = ".png, .jpg, .pdf ,.doc/.docx"
exports.fillingFormIsLengthy = "Kindly upload clear copies of your documents, either as single files or in multiple files. Our dedicated team will handle the sorting and organization of these documents on the backend. Thank you for your cooperation."
exports.academicsText = "Please attach your academics in chronological order in single file."
exports.englishText = "IELTS/ PTE/ MOI/ 12th transcript (if taking waiver on 12th english marks ) is required to be added."
exports.JoinTxt = "Join portal as";
exports.personalInformation = "Personal Information";
exports.belowTxt = "Signing up with us is an effortless process. Once you submit the form";
exports.belowTxt2 = "we'll email you with the warm welcome and all the information you need to know.";
exports.loginTxt = " Log in";
exports.singUpTxt = "Sign up";
exports.emailTxt = "Email";
exports.asAgentTxt = "As a Agent";
exports.asStudentTxt = "As a Student";
exports.noEmp = "No. of Employees";
exports.selectEmp = "Select Employees";
exports.selectCountry = "Country";
exports.selectState = "State";
exports.selectCity = "City";
exports.selectCountryPlaceholderTxt = "Select Country";
exports.selectStatePlaceholderTxt = "Select State";
exports.selectCityPlaceholderTxt = "Select City";
exports.agreeTxt = "I agree with ";
exports.agreePrivacyTxt = "Privacy Policy ";
exports.agreeTermsTxt = "Terms and Condtions";
exports.alreadyTxt = "Already have An Account?";
exports.signupTxt = "Sign Up";
exports.starTxt = "*";
exports.workExperienceAddedTxt   = "IELTS/ PTE/ MOI/ 12th transcript (if taking waiver on 12th english marks ) is required to be added."
exports.passportFieldName = "Passport Number";
exports.otherDocumentsAddedTxt = "LOR/ any other relavant documents to be added"
exports.associateBranchFieldName = "Associate Branch Name";
exports.passportPlaceholderName = "Enter Passport Number";
exports.associateBranchNamePlaceholderName = "Enter Associate branch";
exports.companyPlaceholderTxt = "Your Company Name";
exports.emailPlaceholderTxt = "Enter Mail ID";
exports.cityPlaceholderTxt = "City Name";
exports.attachAcademics = "Please attach your academics in chronological order in single file. 10 MB"
exports.thanksTxt = "Thank you for joining, we have received your information We'll review it and get back to you soon.";
exports.signupContentType = "application/json";
exports.signupApiMethod = "POST";
exports.signupEndPoint = "/account_block/accounts";
exports.resetAPIContentType = "application/json";
exports.resetAPIApiMethod = "POST";
exports.resetAPIEndPoint = "bx_block_forgot_password/otps";
exports.studentFormContentType = "multipart/form-data";
exports.studentFormApimethod = "POST";
exports.studentFormEndPoint = "/bx_block_dashboard/students";
exports.countryError= "*Please Select Country";
exports.dateError= "*Please Select Date";
exports.contactError="*Contact Number Is Required"
exports.emergencyPersonError="*Emergency person Name is Required"
exports.emailError= "*Please Enter Valid Email"
exports.remarkError= "*Remark Is Required"
exports.addAnother="+Add Another"
exports.successMessage = "Student Has Been Added."
exports.success = "Success"
exports.close = "Check All Courses"
exports.goOtherCourse = "Go to Other Services"
exports.invalidContact = "Contact number is Invalid."
exports.passPortRegex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
exports.passportValidationMsg = "Invalid Passport Number. Please enter a valid passport number.";
exports.getProfileEndPoint = "account_block/accounts/";
exports.deleteFileUploaded = "Delete File Uploaded"
exports.getCountryCode = "/bx_block_dashboard/students/get_country_code"
exports.validateMobileNumber = "/bx_block_dashboard/students/check_mobile_number"
exports.validatePassport = "bx_block_dashboard/students/passport_exist"
exports.validateEmail = "bx_block_dashboard/students/email_exist"
exports.getAssociateBranchNames = "/account_block/manage_branches"
exports.emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/
exports.invalidMobileNumberErrorText = "Please enter mobile number of 10 digits."
// Customizable Area End

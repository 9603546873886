import React from "react";
// Customizable Area Start
import {
  Button, TextField, Box, Typography, Grid, Select, Snackbar, MenuItem, TableCell, TableRow, TableContainer, Paper, TableHead, Table, TableBody, Card, CardContent, Switch, AppBar, Tabs, Tab, Modal, SvgIcon,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppHeader from "../../../components/src/AppHeader.web";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import "react-datepicker/dist/react-datepicker.css";
import CustomisableUserProfilesController, { configJSON, Props } from "./CustomisableUserProfilesController.web";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import Loader from "../../../components/src/Loader.web"
import { CheckCircle, KeyboardArrowDown } from "@material-ui/icons";
import TodayIcon from "@material-ui/icons/Today";
import Calendar from "react-calendar";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Formik } from "formik";

// added for Agreement
import {
  FileDownload as FileDownloadIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  PictureAsPdf as PictureAsPdfIcon,
  DeleteOutline as DeleteOutlineIcon,
  CheckCircle as CheckCircleIcon,
  Image as ImageIcon,
  Description as DescriptionIcon
} from '@mui/icons-material';
// end for agreement

// added for manage branch
import CreateIcon from '@mui/icons-material/Create';
import { Bounce, ToastContainer } from "react-toastify";
// end for manage branch
type DocumentCategory = 'personal_id' | 'agent_docs' | 'business_registration_certificate';

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  errorCounter = (errors:string | undefined, touched:string | boolean | undefined) =>{
    return errors && touched && (
        <RequiredTxt>{errors}</RequiredTxt>
      )
  }

  renderProfileGrid = () => {
    const { selected } = this.state;
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="profileGrid">
        <Box className="headerBox">
          <Box className="headingBox">
            <Typography className="heading" data-test-id="viewProfileTestID">
              {configJSON.viewProfileTxt}
            </Typography>
            {this.renderProgressBar()}
          </Box>
          <Box className="toggleContainer">
            <ToggleContainer>
              <Box
                data-test-id="handleToggleAboveTstId"
                className={selected === "Account" ? "selectedClass" : "nonSelectedClass"}
                onClick={() => this.handleToggleAbove("Account")}
              >
                {configJSON.accountTxt}
              </Box>
              <Box
                              data-test-id="handleToggleAboveTstId2"
                className={selected === "Cp profile" ? "selectedClass" : "nonSelectedClass"}
                onClick={() => this.handleToggleAbove("Cp profile")}
              >
                {configJSON.cpText}
              </Box>
            </ToggleContainer>
          </Box>
        </Box>
        <Box className="profileBox">
          <Box className="imageBox">
            {this.state.userDetails?.attributes?.image === null ? (
              <img src={IMG_CONST.defaultImg} alt="Preview" className="userImage" />
            ) : (
              <img src={this.state.userDetails?.attributes?.image.url} alt="Preview" className="userImage" />
            )}
            <label htmlFor="image-upload">
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="imageInputBtn"
                onChange={this.handleFileChange}
                data-test-id="profileImgTestId"
              />
              <EditIcon className="editIcon" />
            </label>
          </Box>
          <Box className="textBox">
            <Typography className="userName" data-test-id="companyTestId">
              {this.state.userDetails?.attributes?.company_name}
            </Typography>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.userMemberTxt}</Typography>
              <Typography className="userValue">
                {this.state.userDetails?.attributes?.created_at}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.teamSizeTxt}</Typography>
              <Typography className="userValue">
                {this.state.userDetails?.attributes?.no_of_employees}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox">
              <Typography className="userKey">{configJSON.contactPersonTxt}</Typography>
              <Typography className="userValue">
              {this.state.userDetails?.attributes?.first_name}
              </Typography>
            </Box>
            <Box className="userDetailsInnerMainBox">
                <Box className="innerBox">
                  <img src={IMG_CONST.phoneIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                    {this.state.userDetails?.attributes?.phone_number}
                  </Typography>
                </Box>
              
              {this.state.userDetails?.attributes?.email !== "" && (
                <Box className="innerBox">
                  <img src={IMG_CONST.smsIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                    {this.state.userDetails?.attributes?.email}
                  </Typography>
                </Box>
              )}
              {this.state.userDetails?.attributes?.country !== "" && (
                <Box className="innerBox">
                  <img src={IMG_CONST.locationIcon} className="detailsIcon" />
                  <Typography className="detailsTxtNew">
                    {this.state.userDetails?.attributes?.country}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  renderProgressBar = () => {
    return (
      <ProgressBox>
        <Typography className="percantageTxt">{configJSON.valueProgressBar}</Typography>
        <Box className="linearBox">
          <LinearProgress variant="determinate" value={80} />
        </Box>
        <Button className="sendBtn">{configJSON.sendReviewBtn}</Button>
      </ProgressBox>
    )
  }

  renderMemberBox = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.memberTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder={configJSON.memberNamePrompt}
            className="inputField"
            onChange={this.memberTxtChange}
            value={this.state.memberTxtValue}
            data-test-id="memberNameTestId"
          ></TextField>
        </Box>
        {this.state.memberError && (
          <RequiredTxt>{this.state.memberErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderMemberEmail = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.memberEmailTxt} <span className="starTxt">{configJSON.startTxt}</span></Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder="Enter email address"
            className="inputField"
            data-test-id="emailTestId"
            onChange={this.memberEmailChange}
            value={this.state.memberEmailTxtValue}
          ></TextField>
        </Box>
        {this.state.memberEmailError && (
          <RequiredTxt>{this.state.memberEmailErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  }

  renderGenderBox = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.genderTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <SelectInput
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            style={{
              color: this.state.genderValue == "none" ? "#999" : "#000"
            }}
            value={this.state.genderValue}
            data-test-id="genderTestId"
            IconComponent={KeyboardArrowDown}
            onChange={this.genderChange}
          >
            {this.state.genderValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberGenderPrompt}
              </MenuItem>
            )}
            <MenuItem value="Male">{configJSON.genderMale}</MenuItem>
            <MenuItem value="Female">{configJSON.genderFemale}</MenuItem>
            <MenuItem value="Other">{configJSON.genderOther}</MenuItem>
          </SelectInput>
        </Box>
        {this.state.genderError && (
          <RequiredTxt>{this.state.genderErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderCountryBox = () => {
    return (

      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.countryTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <Select
            id="myDropdown"
            className="selectField"
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            IconComponent={KeyboardArrowDown}
            defaultValue="default"
            onChange={this.handleCountryChange}
            value={this.state.countryValue}
            data-test-id="countryDataTestId"
            style={{
              color: this.state.countryValue == "none" ? "#999" : "#000"
            }}
          >
            {this.state.countryValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberCountryPrompt}
              </MenuItem>
            )}
            {this.state.countryData.map((item) => (
              <MenuItem value={item.isoCode}>{item.name}</MenuItem>
            ))}
          </Select>
        </Box>
        {this.state.countryError && (
          <RequiredTxt>{this.state.countryErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderStateBox = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.stateTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <Select
            id="myDropdown"
            className="selectField"
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={KeyboardArrowDown}
            defaultValue="default"
            value={this.state.stateValue}
            data-test-id="stateDataTestId"
            style={{
              color: this.state.stateValue == "none" ? "#999" : "#000"
            }}
            onChange={this.handleStateChange}
          >
            {(this.state.stateValue) == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberStatePrompt}
              </MenuItem>
            )}
            {this.state.stateData.map((item: { name: string }) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
        </Box>
        {this.state.stateError && (
          <RequiredTxt>{this.state.stateErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderCityBox = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.cityTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder="Enter city name"
            className="inputField"
            onChange={this.memberCityChange}
            value={this.state.cityValue}
            data-test-id="cityDataTestId"
          ></TextField>
        </Box>
        {this.state.cityError && (
          <RequiredTxt>{this.state.cityErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderCalendar = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.birthdayTxt} <span className="starTxt">{configJSON.startTxt}</span></Typography>
        <Box className="inputFieldBox">
          <Box
            className="inputFieldCalender"
            onClick={this.onCalenderClick}
            data-test-id="dateTestID"
          >
            <Typography style={{ color: this.state.birthDateValue == "Select birthday" ? "#999" : "#000" }} data-test-id="calenderValueTestId">{this.state.birthDateValue?.toString()}</Typography>
            <TodayIcon className="calendarIcon" />
          </Box>
          {this.state.isCalender &&
            <Calendar
              onClickDay={this.handleDateChange}
              value={this.state.birthDate}
              className="calenderBox"
              data-test-id="calenderTestID"
              maxDate={new Date()}
            />
          }
          {this.state.birthdayError && (
            <RequiredTxt>{this.state.birthdayErrorTxt}</RequiredTxt>
          )}
        </Box>
      </Box>
    )
  };

  renderMaritalStatus = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">
          {configJSON.maritalStatusTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <SelectInput
            displayEmpty
            MenuProps={{
              disableScrollLock: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            style={{
              color: this.state.maritalValue == "none" ? "#999" : "#000"
            }}
            value={this.state.maritalValue}
            IconComponent={KeyboardArrowDown}
            onChange={this.maritalChange}
            data-test-id="maritalTestId"
          >
            {this.state.maritalValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.memberMaritalStatusPrompt}
              </MenuItem>
            )}
            <MenuItem value="Single">{configJSON.maritalSingle}</MenuItem>
            <MenuItem value="Married">{configJSON.maritalMarried}</MenuItem>
          </SelectInput>
        </Box>
        {this.state.maritalError && (
          <RequiredTxt>{this.state.maritalErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderMarrigeBox = () => {
    const isDisabled = this.state.maritalValue !== "Married";
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.marriageTxt}</Typography>
        <Box className="inputFieldBox">
          <Box
            className="inputFieldCalender"
            onClick={!isDisabled ? this.onMarrigeCalendarClick : undefined}
            data-test-id="dateMarriageTestID"
            style={{backgroundColor: isDisabled ? "#e0e0e0" : "transparent",}}
          >
            <Typography
              style={{ color: this.state.marriageDateValue == "Select marriage anniversary" ? "#999" : "#000" }} data-test-id="calenderValueTestId">
               {isDisabled ? "" : this.state.marriageDateValue?.toString()}
            </Typography>
            <TodayIcon className="calendarIcon" />
          </Box>
          {this.state.isMarriageCalender &&
            <Calendar
              onClickDay={this.handleMarrigeDateChange}
              value={this.state.marriageDate}
              className="calenderBox"
              data-test-id="calenderMarriageTestID"
              maxDate={new Date()}
            />
          }
          {this.state.marriageError && (
            <RequiredTxt>{this.state.marriageErrorTxt}</RequiredTxt>
          )}
        </Box>
      </Box>
    )
  };

  renderInfoForm = () => {
    return (
      <form data-test-id="submitFormId"
        onSubmit={this.handleSubmit}>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Typography className="heading">{configJSON.personalTxt}</Typography>
          <Grid container className="personalInfo">
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderMemberBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderCountryBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderMaritalStatus()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderMobileBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderStateBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderMarrigeBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderMemberEmail()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderCityBox()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderCalendar()}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="infoFormField">
              {this.renderGenderBox()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="btnGrid">
          <Button
            type="submit"
            className="saveInfo"
            data-test-id="submitBtnTestID"
          >
            {configJSON.saveBtnTxt}
          </Button>
        </Grid>
      </form>
    );
  };

  renderSecurityForm = () => {
    return (
      <form onSubmit={this.onPswrdSave} data-test-id="changeSubmitForm">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="securityGrid">
          <Typography className="heading">{configJSON.securityTxt}</Typography>
          <Grid container className="personalInfo">
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.currentPswrdTxt} <span className="starTxt">{configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.currentPasswordPrompt}
                    className="securityInputField"
                    value={this.state.curentPswrd}
                    onChange={this.curentPswrdChange}
                    data-test-id="currentTestID"
                  />
                </Box>
                {this.state.currentPswrdError && (
                  <RequiredTxt>{this.state.currentPswrdErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.newPswrdTxt}<span className="starTxt"> {configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.newPasswordPrompt}
                    className="securityInputField"
                    value={this.state.newPswrd}
                    onChange={this.newPswrdChange}
                    data-test-id="newTestID"
                  />
                </Box>
                {this.state.newPswrdError && (
                  <RequiredTxt>{this.state.newPswrdErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="infoFormField securityFormField">
              <Box className="formFieldBox">
                <Typography className="fieldText">
                  {configJSON.confirmPswrdTxt}<span className="starTxt"> {configJSON.startTxt}</span>
                </Typography>
                <Box className="inputFieldBox">
                  <TextField
                    type="password"
                    placeholder={configJSON.confirmPasswordPrompt}
                    className="securityInputField"
                    value={this.state.confirmPswrd}
                    onChange={this.confirmPswrdChange}
                    data-test-id="confrimTestID"
                  />
                </Box>
                {this.state.confrimPswrdError && (
                  <RequiredTxt>{this.state.confrimPswrdErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="btnGrid">
          <Button type="submit" className="saveInfo" data-test-id="pswrdBtn">
            {configJSON.pswrdBtnTxt}
          </Button>
        </Grid>
      </form>
    );
  };

  renderMobileBox = () => {
    return (
      <Box className="formFieldBox">
        <Typography className="fieldText">{configJSON.mobileTxt} <span className="starTxt">{configJSON.startTxt}</span>
        </Typography>
        <Box className="inputFieldBox">
          <TextField
            type="text"
            placeholder="Enter mobile phone number"
            className="inputField"
            onChange={this.mobileChange}
            value={this.state.mobileNumberValue}
            data-test-id="mobileNumberTstId"
          ></TextField>
        </Box>
        {this.state.mobileNumberError && (
          <RequiredTxt>{this.state.mobileNumberErrorTxt}</RequiredTxt>
        )}
      </Box>
    )
  };

  renderActivityTable = () => {
    return (
      <TableMainBox>
        <Typography className="heading">{configJSON.activityTxt}</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{configJSON.tableHeader1}</TableCell>
                <TableCell align="center">{configJSON.tableHeader2}</TableCell>
                <TableCell align="center">{configJSON.tableHeader3}</TableCell>
                <TableCell align="center">{configJSON.tableHeader4}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.activityTableData.map((rowValue) => {
                  let dataValue = rowValue.attributes;
                  return (
                    <TableRow key={rowValue.id}>
                      <TableCell align="center">{dataValue.action}</TableCell>
                      <TableCell align="center">{dataValue.description}</TableCell>
                      <TableCell align="center">{dataValue.ip_address}</TableCell>
                      <TableCell align="center">{dataValue.date}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TableMainBox>)
  };

  renderNotification = () => {
    const { notifications, allowAll } = this.state;
    return (
      <NotificationBox>
        <Typography className="heading maarginBottom">
          {configJSON.notificationsTxt}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent style={{ padding: "0px" }}>
                <div className="alllowAllBox">
                  <Box className="imageTxtBox">
                    <img src={IMG_CONST.emailImg} className="emailImg" alt="Email Icon" />
                    <Typography className="emailTxt" variant="h6">
                      {configJSON.emailNotification}
                    </Typography>
                  </Box>
                  <div className="imageTxtBox">
                    <Typography className="normalTxt" variant="body1">
                      Allow all
                    </Typography>
                    <IOSSwitch
                      className="handleToggleNotificationsTstid"
                      checked={allowAll}
                      onChange={this.handleAllowAllToggle}
                      color="primary"
                      data-test-id="handleAllowAllToggleTstId"
                    />
                  </div>
                </div>

                <Grid container direction="column">
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.applicationsStage}
                    </Typography>
                    <IOSSwitch
                      data-test-id="switchTestId"
                      checked={notifications.applicationStage}
                      onChange={this.handleToggleNotifications("applicationStage")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.applicationBulk}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.bulkUpdates}
                      onChange={this.handleToggleNotifications("bulkUpdates")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.conversationMsg}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.conversationMessage}
                      onChange={this.handleToggleNotifications("conversationMessage")}
                      color="primary"
                    />
                  </Grid>
                  <Grid item className="gridBox">
                    <Typography variant="body1" className="normalTxt">
                      {configJSON.newApplicationRequest}
                    </Typography>
                    <IOSSwitch
                      checked={notifications.newApplicationRequirement}
                      onChange={this.handleToggleNotifications("newApplicationRequirement")}
                      color="primary"
                      data-test-id="newApplicationRequirementTstId"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            {this.renderWhatsappBox()}
          </Grid>
        </Grid>
      </NotificationBox>
    )
  };

  renderWhatsappBox = () => {
    return (
      <WhatsAppBox>
        <Card>
          <CardContent className="insideBox">
            <img src={IMG_CONST.whatsAppIcon} alt="WhatsApp Icon" />
            <Typography className="comingSoonTxt">{configJSON.whatsappComingSoonTitle}</Typography>
            <Typography variant="body2" className="comingSoonDescTxt">
              {configJSON.whatsappComingSoonDesc}
            </Typography>
          </CardContent>
        </Card>
      </WhatsAppBox>
    );
  };

  renderBank = () => {
    const { allBankDetails } = this.state;
    return (
      <StyledBankCard>
        <Grid container spacing={2}>
          {allBankDetails.map((detail, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Card>
                <CardContent style={{ height: '100%' }}>
                  <Typography className="bankHeadingTxt">{detail.attributes.bank_name}</Typography>
                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.accountHolderNameLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bankholder_name}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.accountNumberLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.account_number}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.bankNameLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bank_name}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.bankAddressLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.bank_address}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.banIfscLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.iban}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.swiftCodeLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.swiftcode || 'N/A'}</Typography>
                  </Box>

                  <Box className="rowBox">
                    <Typography className="headingBoldTxt">{configJSON.currencyPreferenceLabel}</Typography>
                    <Typography className="detailsTxt">{detail.attributes.currency_preference}</Typography>
                  </Box>

                  <Box className="editDeleteBtnBox">
                    <Box style={{display:"flex", gap:"8px"}}>
                      <Button startIcon={<EditIcon />} data-test-id={`BrachEdit${index}`} className="editBtn" onClick={() => this.modelOpen(detail.attributes.id)}>{configJSON.editButtonText}</Button>
                      <Button startIcon={<DeleteIcon />} className="deleteBtn" data-test-id="onDeleteTestId" onClick={() => this.handleDeleteID(detail.attributes.id, detail.attributes.bank_name)}>{configJSON.deleteButtonText}</Button>
                    </Box>
                    {allBankDetails.length === 2 && (
                      <Box>
                        <IOSSwitch
                          className="handleToggleNotificationsTstid"
                          checked={detail.attributes.primary}
                          onChange={(event) => this.updateBankPrimary(event, detail.attributes.id)}
                          color="primary"
                          data-test-id={`handlePrimaryBank${index}`}
                          disabled={detail.attributes.primary}
                        />
                      </Box>
                    )}
                  </Box>
                 
                </CardContent>
              </Card>
            </Grid>
          ))}

          {allBankDetails.length <= 1 && (
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent className="cardContentBox">
                  <Button className="addBankBtn" startIcon={<AddIcon />} onClick={() => this.modelOpen()}>
                    {configJSON.addNewBankAccountButtonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </StyledBankCard>
    );
  };

  renderBankModal = () => {
    const { isBankEdit, bankErrorResponse } = this.state;
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.isModal}
        className="modal"
      >
        <Paper className="paper">
        <Typography className="heading">{isBankEdit ? "Edit Bank Account" : configJSON.bankNameHeading}</Typography>

            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.accountHolderName} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter Account Holder Name"
                  className="inputField"
                  onChange={this.changeAccountName}
                  data-test-id="accountTestId"
                  value={this.state.accountNameValue}
                />
                {this.state.accountNameError && (
                  <RequiredTxt>{this.state.accountNameErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.accountName} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter Account Number"
                  className="inputField"
                  onChange={this.changeAccountNumber}
                  data-test-id="accountNumber"
                  value={this.state.accountNumberValue}
                />
                {this.state.accountNumberError && (
                  <RequiredTxt>{this.state.accountNumberErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.bankNameTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter bank name"
                  className="inputField"
                  onChange={this.changeAccountBankName}
                  data-test-id="bankTestID"
                  value={this.state.accountBankNameValue}
                />
                {this.state.accountBankNameError && (
                  <RequiredTxt>{this.state.accountBankNameErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.bankAdrsTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter bank address"
                  className="inputField"
                  onChange={this.changeBankAdrs}
                  data-test-id="accountAdrsTestId"
                  value={this.state.bankAdrsValue}
                />
                {this.state.bankAdrsError && (
                  <RequiredTxt>{this.state.bankAdrsErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.ibanTxt} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder="Enter IBAN/IFSC"
                  className="inputField"
                  onChange={this.changeIban}
                  data-test-id="ibanTestID"
                  value={this.state.ibanValue}
                />
                {this.state.ibanError && (
                  <RequiredTxt>{this.state.ibanErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>
            <Box className="formFieldBox">
              <Typography className="fieldText">
                {configJSON.swiftCodeTxt}
              </Typography>
              <Box className="inputFieldBox">
                <TextField
                  type="text"
                  placeholder={configJSON.shiftCodePlaceHolder}
                  className="inputField"
                  onChange={this.changeShiftCode}
                  data-test-id="shiftTestID"
                  value={this.state.shiftValue}
                />
                {this.state.shiftError && (
                  <RequiredTxt>{this.state.shiftErrorTxt}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                {configJSON.currencyPreferenceLabel} <span className="starTxt">{configJSON.startTxt}</span>
              </Typography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                style={{
                  color: this.state.stateValue === "none" ? "#999" : "#000"
                }}
                value={this.state.selectedCurrency}
                IconComponent={KeyboardArrowDown}
                data-test-id="bankCurrencySelect"
                defaultValue="default"
                onChange={this.handleChangeCurrency}
                error={!!this.state.currencyError}
              >
                <MenuItem key={0} value={"none"}>{`Select Currency preference`}</MenuItem>
                {this.state.currencyCode?.map((item) => (
                  <MenuItem key={item.code} value={item.code}>{`${item.country} (${item.code})`}</MenuItem>
                ))}
              </SelectInput>
              {this.state.currencyError && (
                  <RequiredTxt>{this.state.currencyErrorTxt}</RequiredTxt>
                )}
            </Box>

            <Box className="formFieldBox">
                      <List style={{ padding: 0 }}>
            {bankErrorResponse && bankErrorResponse.map((errorObj: any, index) => (
              Object.keys(errorObj).map((key) => (
                <ListItem key={`${index}-${key}`} style={{ padding: '8px 0' }}>
                  <ListItemText
                    primary={`${key}: ${errorObj[key].join(', ')}`}
                    primaryTypographyProps={{ style: { color: 'red' } }}
                  />
                </ListItem>
              ))
            ))}
          </List>
            </Box>
            <Box className="buttonBox">
              <Button
                className="cancelBtn"
                onClick={() => this.modelOpen()}
                data-test-id="modelOpenTestID"
              >
                {configJSON.cancleBtnTxt}
              </Button>
              <Button
                type="submit"
                className="addBtn"
                data-test-id="submitBankInfo"
                onClick={isBankEdit ? this.updateBankInfo : this.saveBankInfo}
              >
                {isBankEdit ? "Update Bank Account" : configJSON.addBtnTxt}
              </Button>
            </Box>
        </Paper>
      </MainModal>
    );
  };
    
  renderManageStaff = () => {
    const { expandedRowId } = this.state;
    return (
      <TablePaper>
        <Table className="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.staffSerialNo}</TableCell>
              <TableCell>{configJSON.staffFirstName}</TableCell>
              <TableCell>{configJSON.staffLastName}</TableCell>
              <TableCell>{configJSON.staffEmail}</TableCell>
              <TableCell>{configJSON.staffDesignation}</TableCell>
              <TableCell>{configJSON.staffRole}</TableCell>
              <TableCell>{configJSON.staffMobile}</TableCell>
              <TableCell>
              {configJSON.staffStatus} <ExpandMoreIcon className="expandMore"/>
              </TableCell>
              <TableCell>{configJSON.staffActions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(this.state.staffRowData) && this.state.staffRowData.length > 0 ? this.state.staffRowData.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell data-test-id={`manageStaffRowName${index}`}>{row.attributes.first_name}</TableCell>
                  <TableCell>{row.attributes.last_name}</TableCell>
                  <TableCell>{row.attributes.email}</TableCell>
                  <TableCell>{row.attributes.role}</TableCell>
                  <TableCell>{row.attributes.role}</TableCell>
                  <TableCell>
                    + {row.attributes.country_code} -{" "}
                    {row.attributes.phone_number}
                  </TableCell>
                  <TableCell align="right">
                    <span
                      className={
                        row.attributes.status === "active"
                          ? "statusActive"
                          : "statusInactive"
                      }
                    >
                      {row.attributes.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    {row.attributes.status === "active" && <Box className="tcAssigned">
                      <Button variant="outlined" size="small" className="borderedSpan">
                        <EditIcon className="editIconTable" />{" "}
                      </Button>
                      <Button onClick={() => this.handleRowClick(row.id)} data-test-id={`manageStaffRow${index}`} variant="outlined" size="small" className="borderedSpan">
                        {4} {configJSON.asignedDummyValue}<ExpandMoreIcon className="expandMore" />
                      </Button>
                    </Box>
                    }
                  </TableCell>
                </TableRow>

                {expandedRowId === row.id && row.attributes.status === "active"&& (
                  <TableRow>

                    <TableCell colSpan={12} className="tableCellExpanded">
                      <ExpandedBox>
                        <Box className="expandedContent" component={Paper}>
                          <Box className="boxTop">
                            <Box className="topLeft">
                              <Box className="appIdBox">
                                <div data-test-id={`expandedTestId${index}`}>{configJSON.appIdText}</div>
                                <div>{configJSON.dummyAppId}</div>
                              </Box>
                              <Box className="appIdBox">
                                <div>{configJSON.statusText}</div>
                                <div className="statusInprogress">
                                  {configJSON.dummyStatus}
                                </div>
                              </Box>
                            </Box>
                            <Button className="viewAppBtn">{configJSON.viewAppBtnText}</Button>
                          </Box>
                          <Box className="middleBox">
                            <span className="assignedTo">{configJSON.assignedToText}</span>
                            <span className="reasignBtn">{configJSON.reassignText}</span>
                          </Box>
                          <Box className="boxBottom">
                            <Box className="boxBottomInner">
                              <Box className="bottomLeft">
                                <p>{configJSON.userNameTxt}</p>
                                <p>{configJSON.designationTxt}</p>
                                <p>{configJSON.emailTxt}</p>
                              </Box>
                              <Box className="bottomRight">
                                <p className="textBold">{configJSON.dummyName}</p>
                                <p className="textBold">{configJSON.dummyDesignation}</p>
                                <p className="textBold">{configJSON.dummyLongEmail}</p>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box component={Paper} className="expandedContent">
                          <Box
                            className="boxTop">
                            <Box
                              className="topLeft">
                             <Box
                                className="appIdBox">
                                <div
                                  data-test-id={`expandedTestId${index}`}>
                                  {configJSON.appIdText}
                                </div>
                                <div>
                                  {configJSON.dummyAppId}
                                </div>
                              </Box>
                              <Box
                                className="appIdBox">
                                <div>
                                  {configJSON.statusText}
                                </div>
                                <div
                                  className="statusInprogress">
                                  {configJSON.dummyStatus}
                                </div>
                              </Box>
                            </Box>
                            <Button
                              className="viewAppBtn">
                              {configJSON.viewAppBtnText}
                            </Button>
                          </Box>
                          <Box
                            className="middleBox">
                            <span
                              className="assignedTo">
                              {configJSON.assignedToText}
                            </span>
                            <span
                              className="reasignBtn">
                              {configJSON.reassignText}
                            </span>
                          </Box>
                          <Box
                            className="boxBottom">
                            <Box
                              className="boxBottomInner">
                              <Box
                                className="bottomLeft">
                                <p>
                                  {configJSON.userNameTxt}
                                </p>
                                <p>
                                  {configJSON.designationTxt}
                                </p>
                                <p>
                                  {configJSON.emailTxt}
                                </p>
                              </Box>
                              <Box
                                className="bottomRight">
                                <p
                                  className="textBold">
                                  {configJSON.dummyName}
                                </p>
                                <p
                                  className="textBold">
                                  {configJSON.dummyDesignation}
                                </p>
                                <p
                                  className="textBold">
                                  {configJSON.dummyNormalEmail}
                                </p>
                              </Box>
                               </Box> 
                               </Box>
                        </Box>
                        </ExpandedBox>
                      <ReassignActions>
                        <Box className="reassignLeft">
                          <span>{4} {configJSON.assignedAppTxt}</span>
                          <Button className="reassignToAllBtn">
                            {configJSON.reassignAllToTxt}
                          </Button>
                        </Box>
                        <Box className="reassignRight">
                          <span>
                            <ErrorOutlineIcon className="iconWarning" />
                          </span>
                          <span className="reassignWarnText">
                           {configJSON.reassignWarnTxt}
                          </span>
                          <Button className="deactivateBtn">{configJSON.deactivateBtntxt}</Button>
                        </Box>
                      </ReassignActions>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )) :
            <TableRow>
              <TableCell>
                <Typography align="center">
                  No Data
                </Typography>
              </TableCell>
            </TableRow>
            }
          </TableBody>
        </Table>
        {this.renderMangeStafModel()}
      </TablePaper>
    );
  };

  renderMangeStafModel = () => {
    const { branchCountryCode, countryCodes, isMangeStaffEdit } = this.state;
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.MangeStaffModel}
        onClose={() => this.OpenCloseMangeStaff()}
        className="modal"
        data-test-id="MangeStaffModel"
      >
        <UpdatedMainModelStyle className="MangeStaff-paper" data-test-id="ManeStaffpaperTestID">
          <Box style={{ width: "100%" }}>
            <Box className="HeadingContainer">
              <Box>
                <Typography className="NewHeading" data-test-id="MangeStaffheadingTestID">
                  {isMangeStaffEdit ? "Edit Staff" : `Add New Staff`}
                </Typography>
                <Typography data-test-id="MangeStaffheadingTestID">
                  Enter a new member's information
                </Typography>
              </Box>
              <BoxPopupClose data-test-id="MangeStaffPopupCloseTestID">
                <CloseIcon data-test-id="MangeStaffcloseIconTestID" onClick={() => this.OpenCloseMangeStaff()} />
              </BoxPopupClose>
            </Box>
            <Box style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  name: this.state.MangeStaffFormData.name || '',
                  lastName: this.state.MangeStaffFormData.lastName || '',
                  email: this.state.MangeStaffFormData.email || '',
                  mobile: this.state.MangeStaffFormData.mobile || '',
                  role: this.state.MangeStaffFormData.role || "",
                  designation: this.state.MangeStaffFormData.designation || ''
                }}
                validationSchema={this.AddNewStaffSchema}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(values) => {
                  if(!this.state.branchMobileNumberError){
                    this.CreateMangeStaff(values)
                  }
                }}
              >{({ handleSubmit, setFieldValue, handleBlur, errors, values, touched }) => {
                return (
                  <form data-test-id="MangeStaffSubmitTestID" onSubmit={handleSubmit} autoComplete="off">
                    <Box style={{ width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              Name<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={"Enter Name"}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'name', setFieldValue)
                                }}
                                name="name"
                                onBlur={handleBlur}
                                data-test-id="MangeStaffNameTestIdName"
                                fullWidth
                                value={values.name}
                                error={!!errors.name}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.name ,touched.name)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              Last name<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={'Enter Last Name'}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'lastName', setFieldValue)
                                }}
                                data-test-id="MangeStaffLastNameTestId"
                                name="lastName"
                                onBlur={handleBlur}
                                value={values.lastName}
                                error={!!errors.lastName}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.lastName ,touched.lastName)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              E-mail<span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={"Enter Email ID"}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'email', setFieldValue)
                                }}
                                data-test-id="MangeStaffEmailTestId"
                                fullWidth
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                error={!!errors.email}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.email, touched.email)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
                              {configJSON.branchMobile} <span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <MobileNumberWrapper className="MangeStaffMobileContainer" data-test-id="mobileNumberWrapperTestID">
                              <Box data-test-id="branchCountryCodeBoxTestID">
                                <SelectInput
                                  displayEmpty
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      horizontal: "left",
                                      vertical: "bottom"
                                    },
                                  }}
                                  style={{
                                    color: this.state.stateValue === "none" ? "#999" : "#000"
                                  }}
                                  value={branchCountryCode}
                                  IconComponent={KeyboardArrowDown}
                                  data-test-id="MangeStaffCountryCodeTestId"
                                  defaultValue="default"
                                  onChange={this.changeBranchCountryCode}
                                >
                                  {countryCodes.map((item) => (
                                    <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>
                                      {`+${item.country_code} (${item.alpha2})`}
                                    </MenuItem>
                                  ))}
                                </SelectInput>
                              </Box>
                              <Box className="inputFieldBox" data-test-id="branchContactNumberBoxTestID">
                                <TextField
                                  type="tel"
                                  placeholder={'Enter Mobile Number'}
                                  className="inputField"
                                  fullWidth
                                  onChange={(e) => {
                                    this.onChangeOfMangeStaff(e, 'mobile', setFieldValue)
                                  }}
                                  data-test-id="MangeStaffPhoneNumberTestId"
                                  name="mobile"
                                  value={values.mobile}
                                  error={!!errors.mobile}
                                  onBlur={this.validateMobileNo}
                                />
                              </Box>
                            </MobileNumberWrapper>
                            {!!this.state.branchMobileNumberError && (
                              <RequiredTxt>{this.state.branchMobileNumberError}</RequiredTxt>
                            )}
                            {!this.state.branchMobileNumberError && values.mobile === ''  && this.errorCounter(errors.mobile, touched.mobile)}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
                              Role <span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={values.role}
                              data-test-id="RadioGroupTest"
                            >
                              <FormControlLabel data-test-id="RoleTestId" name="counsellor" value="counsellor" control={<RadioButton />} label="Councellor"
                                onChange={() => {
                                  this.OnChangeMangeStaffRadioButton("counsellor", 'role', setFieldValue)
                                }}
                              />
                              <FormControlLabel name="Sub admin" value="sub_admin" control={<RadioButton />} label="Sub admin"
                                onChange={() => {
                                  this.OnChangeMangeStaffRadioButton("sub_admin", 'role', setFieldValue)
                                }}
                              />
                            </RadioGroup>
                            {this.errorCounter(errors.role, touched.role)}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box data-test-id="MangeStaffFieldBoxTestID">
                            <BranchTextLabelsTypography data-test-id="MangeStaffLabelTestID">
                              Designation <span className="starTxt">{configJSON.startTxt}</span>
                            </BranchTextLabelsTypography>
                            <Box className="inputFieldBox" data-test-id="MangeStaffInputBoxTestID">
                              <TextField
                                type="text"
                                placeholder={'Enter designation'}
                                className="inputField"
                                onChange={(e) => {
                                  this.onChangeOfMangeStaff(e, 'designation', setFieldValue)
                                }}
                                data-test-id="MangeStaffDesignationTestId"
                                fullWidth
                                name="designation"
                                onBlur={handleBlur}
                                value={values.designation}
                                error={!!errors.designation}
                                inputProps={{
                                  maxLength: 30,
                                }}
                              />
                              {this.errorCounter(errors.designation, touched.designation)}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="buttonBoxBranch" data-test-id="buttonBoxBranchTestID">
                            <Button
                              type="submit"
                              className="addBtn"
                              data-test-id="MangeStaffSubmitButton"
                            >
                              {isMangeStaffEdit ? "Save changes" : `Add new member`}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )
              }}
              </Formik>
            </Box>
          </Box>
        </UpdatedMainModelStyle>
      </MainModal>
    )
  }
  
  rednerTab = () => {
    return (
      <MainTabBox>
        <div >
          <AppBar position="static">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              data-test-id="tabChangeTestId"
            >
              <Tab className="tabLabel" label={configJSON.bankInformationTxt} value={0} data-test-id="bankInfoTestId" />
              <Tab className="tabLabel" label={configJSON.documentsnTxt} value={1} />
              <Tab className="tabLabel" label={configJSON.agreementTxt} value={2} />
              <Tab className="tabLabel" label={configJSON.staffTxt} value={3} data-test-id="manageStaffTab"/>
              <Tab className="tabLabel" label={configJSON.manageBranch} value={4} />
              {this.state.value === 4 && (<AddBranchButton data-test-id="addBranchButton" onClick={() => this.openBranchModal()}><SvgIcon className="SvgIconAddBranch" component={AddIcon} /><AddBranchTypography>{configJSON.addBranchBtn}</AddBranchTypography></AddBranchButton>)}
              {this.state.value === 3 && (<AddBranchButton onClick={this.OpenCloseMangeStaff} data-test-id="addBranchButton"><SvgIcon className="SvgIconAddBranch" component={AddIcon} /><AddBranchTypography>{configJSON.addStaffBtnText}</AddBranchTypography></AddBranchButton>)}
            </Tabs >
          </AppBar >
          {this.tabPanel(this.renderBank(), this.state.value, 0)}
          {this.tabPanel(this.renderDocument(), this.state.value, 1)}
          {this.tabPanel(this.renderAgreement("initial"), this.state.value, 2)}
          {this.tabPanel(this.renderAgreement("success"), this.state.value, 2)}
          {this.tabPanel(this.renderAgreement("failure"), this.state.value, 2)}
          {this.tabPanel(this.renderManageStaff(), this.state.value, 3)}
          {this.tabPanel(this.renderBranch(), this.state.value, 4)}
        </div >
      </MainTabBox >
    );
  };

  tabPanel(children: {}, value: number, index: number) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <TabBox>
            <Typography>{children}</Typography>
          </TabBox>
        )}
      </div>
    );
  };

  renderDelete = () => {
    return (
      <DeleteModalBox open={this.state.isDelete} onClose={this.handleDelete}>
        <Card className="cardBox">
          <CardContent className="cardContent">
            <Box className="box">
            <CloseIcon className="closeIcon" onClick={this.handleDelete} />
            </Box>
            <Typography className="deleteTxtBtn marginTop30">
              {configJSON.deleteBankAccountTitle}
            </Typography>
            <Typography className="confirmationTxt">
            {configJSON.deleteBankAccountConfirmation} <span className="deleteTxtBtnBranch">{this.state.bankEditName}</span> account?
            </Typography>
            <Box className="deleteBtnBottomBox">

            <Button className="deleteModalInsideBtn" onClick={this.deleteBankInfo}>
                {configJSON.deleteButtonText}
              </Button>

              <Button className="confirmModalInsideBtn" onClick={this.handleDelete}>
                {configJSON.goBackBtnText}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DeleteModalBox>
    );
  };
  

  renderStatusContent = (status:string,id:string,agentData:any) => {
    switch (status) {
      case 'initial':
        return (
          <DownloadAgreementButton>
            {/* Check if digital_signature_url exists */}
            {agentData?.attributes?.digital_signature_url ? (
              <>
              {agentData?.attributes?.fix_required?(
                <>
                <FileUploadedError>
                {this.getIconForFileType(agentData.attributes.digital_signature_url)}
                <Box>
                  <FileNameTitleTypography>
                    {/* Display the file name */}
                    <Typography noWrap style={{maxWidth:"140px",overflow:"hidden",textOverflow:"eclipse"}}>
                    {agentData.attributes.digital_signature_url.split('/').pop().slice(0, 8)} 
                    </Typography>
                    </FileNameTitleTypography>
                  <FilesizeTypography>{agentData.attributes.file_size}</FilesizeTypography>
                </Box>
                <ActionButtonsWrapper>
                  {/* Download button */}
                  <a
                    href={agentData.attributes.digital_signature_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <DownloadIconButton>
                      <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                    </DownloadIconButton>
                  </a>
                  {/* Delete button */}
                  <DeleteIconButton data-test-id="delete-agreement-id"  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>  this.handleDeleteDocAgree(event, agentData.attributes.id)}>
                  <SvgIcon component={DeleteOutlineIcon} style={{ color: '#DC2626' }} />
                </DeleteIconButton>

                </ActionButtonsWrapper>
                </FileUploadedError>
                 <FileUploadErrorText dangerouslySetInnerHTML={{ __html: agentData.attributes.comment}} />
               </>
              ):(
                <>
                <FileUploaded>
                {this.getIconForFileType(agentData.attributes.digital_signature_url)}
                <Box>
                  <FileNameTitleTypography noWrap style={{maxWidth:"140px",overflow:"hidden",textOverflow:"eclipse"}}>
                    {/* Display the file name */}
                    {agentData.attributes.digital_signature_url.split('/').pop()}
                  </FileNameTitleTypography>
                  <FilesizeTypography>{agentData.attributes.file_size}</FilesizeTypography>
                </Box>
                <ActionButtonsWrapper>
                  {/* Download button */}
                  <a
                    href={agentData.attributes.digital_signature_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <DownloadIconButton>
                      <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                    </DownloadIconButton>
                  </a>
                  {/* Delete button */}
                  <DeleteIconButton  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>  this.handleDeleteDocAgree(event, agentData.attributes.id)}>
                  <SvgIcon component={DeleteOutlineIcon} style={{ color: '#DC2626' }} />
                </DeleteIconButton>

                </ActionButtonsWrapper>
              </FileUploaded>
                </>
              )}
              </>
              // If the digital_signature_url exists, show the file with download and delete functionality
              
            ) : (
              // If digital_signature_url does not exist, show the upload button
              <>
                <DownloadDocumentText>{configJSON.uploadFile}</DownloadDocumentText>
                <AddDocumentBox className="agreement-upload" style={{margin:"0 auto", height:"41px",border:"none" }}>
                    <input
                      accept=".png, .jpg, .pdf, .doc, .docx"
                      style={{ display: 'none'}} 
                      id='input-agree' 
                      type="file"
                      data-test-id="fileInputTestIDnew" 
                      onChange={(event) => this.handleFileChangeDocAgreement(event, id)}
                    />
                    <label htmlFor='input-agree'> 
                      <UploadDocumentButton
                        data-test-id="addDocumentTestId"
                        onClick={() => document.getElementById('input-agree')?.click()}
                      >
                        <SvgIcon component={AddIcon} style={{ color: '#34D399' }} />
                        <DocUpLoadText>{configJSON.documentAddText}</DocUpLoadText>
                      </UploadDocumentButton>
                    </label>
                  </AddDocumentBox>

              </>
            )}
          </DownloadAgreementButton>
        );
      default:
        return null;
    }
  };

  renderDoneComponent = (status:string) => {
    switch (status) {
      case 'initial':
        return (
          <>
            <SvgIcon component={CheckCircleIcon} style={{ color: "#94A3B8B2" }} />
            <DoneTypography>{configJSON.doneText}</DoneTypography>
          </>
        );
      case 'success':
        return (
          <>
            <SvgIcon component={CheckCircleIcon} style={{ color: "#34D399" }} />
            <DoneTypography>{configJSON.doneText}</DoneTypography>
          </>
        );
      case 'failure':
        return <FixedRequiredText>{configJSON.failedText}</FixedRequiredText>
    }
  };
  

renderAgreement = (currentStatus:string) => {
  if (currentStatus === 'initial') {
  
    return (
      this?.state?.allAgreementsData?.map((doc: any) => {
        // Check if the document ID is in the viewedDocuments array
        const isDocumentViewed = this.state.viewedDocuments.includes(doc.id);
        const hasError =doc?.attributes?.agent_agreement?.attributes?.fix_required;

        
            let ComponentToRender;
            if (hasError) {
              ComponentToRender = <BackgroundLineFailure />;
            } else if (isDocumentViewed) {
              ComponentToRender = <BackgroundLineSuccess />;
            } else {
              ComponentToRender = <BackgroundLine />;
            }

        return (
          <TermsAndServiceWrapper key={doc.id}>
            <SectionTitle>{configJSON.sectionTitle}</SectionTitle>
            <InstructionTitle>{configJSON.acceptedFileFormats}</InstructionTitle>
            <AgreementContainer>
              <DownloadAgreementButton>
                <DownloadDocumentText data-test-id="test-id">{configJSON.downloadText}</DownloadDocumentText>
                <DownloadFileButton>
                  <a
                    href={doc.attributes?.file_attachment[0]?.url}
                    onClick={() => this.handleDocumentViewed(doc.id)}
                    style={{"textDecoration":"none","display": "flex"}}
                    target="_blank"
                    data-test-id="viewDocId"
                    rel="noopener noreferrer"
                    key={doc.id}
                  >
                    <DownLoadText>{configJSON.downloadBtnText}</DownLoadText>
                    <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                  </a>
                </DownloadFileButton>
              </DownloadAgreementButton>
              <ReadItThrough>
                <ReadWrapper>
                  <SvgIcon component={VisibilityOutlinedIcon} style={{ color: '#64748B' }} />
                  <ReadItTypography>{configJSON.readItThrough}</ReadItTypography>
                </ReadWrapper>
              </ReadItThrough>
              {this.renderStatusContent(currentStatus, doc.attributes?.id, doc?.attributes?.agent_agreement)}
              <ReadItThrough>
                <DoneWrapper>
                  {this.renderDoneComponent(currentStatus)}
                </DoneWrapper>
              </ReadItThrough>
              {ComponentToRender}
            </AgreementContainer>
            </TermsAndServiceWrapper>
        );
      })
    );
  }

  // If status is not 'initial', return null or handle based on actual need
  return null;
};



  renderBranch = () => {
    return (
      <Grid container spacing={2}>
        {this.state.allBranchData?.map((branch) => (
          <Grid item xs={12} sm={6} md={4}  key={branch.id}>
             <BranchCard>
            <BranchNameAndDateWrapper>
              <BranchName>{branch.attributes.branch_name}</BranchName>
              <BranchCreatedDate>
                Created on {branch.attributes.date} - {branch.attributes.time}
              </BranchCreatedDate>
            </BranchNameAndDateWrapper>
            <BranchDetailsWrapper>
              <DetailRow>
                <DetailLabel>Email</DetailLabel>
                <DetailValue>{branch.attributes.email}</DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailLabel>Phone number</DetailLabel>
                <DetailValue>
                  +{branch.attributes.country} {branch.attributes.phone_number}
                </DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailLabel>Managed by</DetailLabel>
                <DetailValue>{branch.attributes.managed_by || 'N/A'}</DetailValue>
              </DetailRow>
            </BranchDetailsWrapper>
            <ActionButtonWrapper>
              <EditBranchButton onClick={() => this.openBranchModal(branch.attributes.id)}>
                <SvgIcon
                  component={CreateIcon}
                  style={{ color: '#34D399', width: "20px", height: "20px" }}
                />
                <EditBranchTypography>Edit</EditBranchTypography>
              </EditBranchButton>
              <DeleteBranchButton onClick={() => this.handleDeleteBranchID(branch.attributes.id, branch.attributes.branch_name)}>
                <SvgIcon
                  component={DeleteOutlineIcon}
                  style={{ color: '#DC2626', width: "20px", height: "20px" }}
                />
                <DeleteBranchTypography>Delete</DeleteBranchTypography>
              </DeleteBranchButton>
            </ActionButtonWrapper>
          </BranchCard>
          </Grid>
        ))}
      </Grid>
    )
  }

  renderBranchModal = () => {
    const { branchName, branchAgent, branchEmailID, branchCountryCode, branchMobileNumber, countryCodes, isBranchEdit } = this.state;
    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={this.state.branchModal}
        onClose={() => this.openBranchModal()}
        className="modal"
        data-test-id="mainModalTestID"
      >
        <Paper className="paper" data-test-id="paperTestID">
          <BoxPopupClose data-test-id="boxPopupCloseTestID">
            <CloseIcon data-test-id="closeIconTestID" onClick={() => this.openBranchModal()} />
          </BoxPopupClose>
          <Typography className="heading" data-test-id="headingTestID">
            {isBranchEdit ? "Edit Branch" : `${configJSON.addBranchPopupTitle}`}
          </Typography>
          <form data-test-id="branchSubmitTestID" onSubmit={isBranchEdit ? this.handleSubmitBranchEdit : this.handleSubmitBranch}>
            <Box className="formFieldBox" data-test-id="branchNameFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchNameLabelTestID">
                {configJSON.branchName}<span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <Box className="inputFieldBox" data-test-id="branchNameInputBoxTestID">
                <TextField
                  type="text"
                  placeholder={configJSON.branchNamePlaceholder}
                  className="inputField"
                  onChange={this.changeBranchName}
                  data-test-id="branchNameTestId"
                  value={branchName}
                  error={!!this.state.branchNameError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {!!this.state.branchNameError && (
                  <RequiredTxt>{this.state.branchNameError}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox" data-test-id="branchEmailFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchEmailLabelTestID">
                {configJSON.branchEmail} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <Box className="inputFieldBox" data-test-id="branchEmailInputBoxTestID">
                <TextField
                  type="text"
                  placeholder={configJSON.branchEmailPlaceholder}
                  className="inputField"
                  onChange={this.changeBranchEmail}
                  data-test-id="branchEmailID"
                  value={branchEmailID}
                  error={!!this.state.branchEmailError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {!!this.state.branchEmailError && (
                  <RequiredTxt>{this.state.branchEmailError}</RequiredTxt>
                )}
              </Box>
            </Box>

            <Box className="formFieldBox" data-test-id="branchMobileFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="branchMobileLabelTestID">
                {configJSON.branchMobile} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <MobileNumberWrapper data-test-id="mobileNumberWrapperTestID">
                <Box data-test-id="branchCountryCodeBoxTestID">
                  <SelectInput
                    displayEmpty
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                      },
                    }}
                    style={{
                      color: this.state.stateValue === "none" ? "#999" : "#000"
                    }}
                    value={branchCountryCode}
                    IconComponent={KeyboardArrowDown}
                    data-test-id="branchCountryCodeTestId"
                    defaultValue="default"
                    onChange={this.changeBranchCountryCode}
                  >
                    {countryCodes.map((item) => (
                      <MenuItem key={`${item.country_code}-${item.alpha2}`} value={`${item.country_code}-${item.alpha2}`}>
                        {`+${item.country_code} (${item.alpha2})`}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Box>
                <Box className="inputFieldBox" data-test-id="branchContactNumberBoxTestID">
                  <TextField
                    name="number"
                    type="tel"
                    placeholder={configJSON.branchMobile}
                    className="inputField"
                    onChange={this.changeBranchMobile}
                    data-test-id="branchContactNumberTestId"
                    value={branchMobileNumber}
                    error={!!this.state.branchMobileNumberError}
                    onBlur={this.validateMobileNo}
                  />
                  {!!this.state.branchMobileNumberError && (
                    <RequiredTxt>{this.state.branchMobileNumberError}</RequiredTxt>
                  )}
                </Box>
              </MobileNumberWrapper>
            </Box>

            <Box className="formFieldBox" data-test-id="manageMemberFieldBoxTestID">
              <BranchTextLabelsTypography data-test-id="manageMemberLabelTestID">
                {configJSON.manageMember} <span className="starTxt">{configJSON.startTxt}</span>
              </BranchTextLabelsTypography>
              <TextField
                  type="text"
                  placeholder={configJSON.manageMember}
                  className="inputField"
                  onChange={this.handleChangeBranchAgent}
                  data-test-id="branchManagedByTestId"
                  value={branchAgent}
                  error={!!this.state.branchAgentError}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              {!!this.state.branchAgentError && (
                <RequiredTxt>{this.state.branchAgentError}</RequiredTxt>
              )}
            </Box>

            <Box className="buttonBoxBranch" data-test-id="buttonBoxBranchTestID">
              <Button
                type="submit"
                className="addBtn"
                data-test-id="addBranchButtonTestID"
              >
                {isBranchEdit ? "Update Branch" : `${configJSON.addBranchBtn}`}
              </Button>
            </Box>
          </form>
        </Paper>
      </MainModal>
    );
  };


  getIconForFileType = (fileName:any) => {
    if (fileName.endsWith('.pdf')) return <PictureAsPdfIcon style={{ color: '#000000' }} />;
    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) return <DescriptionIcon style={{ color: '#000000' }} />;
    if (fileName.endsWith('.png') || fileName.endsWith('.jpg')) return <ImageIcon style={{ color: '#000000' }} />;
  };

  renderDocument = () => {
    const renderDocumentItems = (title: string, documents: Document[], category: DocumentCategory) => {
      const fileInputId = `file-upload-input-${category}`;
      return (
        <DocumentWrapper>
          <DocumentInnerWrapper>
            <UploadDocTitle>{title}</UploadDocTitle>
            <DocumentUploadRule>Accepted file format: .png, .jpg, .pdf, .doc, .docx</DocumentUploadRule>
            <InnerBox>
              {documents.map((doc) => (
                <UploadDocumentWrapper key={doc.id}>
                  <DocumentSelected>
                    <DocumentDetailWrapper>
                      <DocumentAndDelete>
                      <PDFDocumentBox>
                    {this.getIconForFileType(doc.attributes.name)}
                  </PDFDocumentBox>
                        <DeleteDocumentBox
                          onClick={(event) => this.handleDeleteDoc(event as React.MouseEvent<HTMLDivElement, MouseEvent>, doc.id)}>
                          <SvgIcon className="DeleteDocument" component={DeleteOutlineIcon} style={{ color: '#DC2626' }} />
                        </DeleteDocumentBox>
                      </DocumentAndDelete>
                      <DocumentDetails>
                        <DocumentNameTypography>{doc.attributes.name}</DocumentNameTypography>
                        <DocumentSizeTypography>{doc.attributes.file_size}</DocumentSizeTypography>
                      </DocumentDetails>
                      <DownloadDocumentButton>
                        <a
                          style={{ textDecoration: 'none', display: 'flex' }}
                          href={doc.attributes.url.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <DownLoadText>{configJSON.downloadBtnText}</DownLoadText>
                          <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                        </a>
                      </DownloadDocumentButton>
                    </DocumentDetailWrapper>
                  </DocumentSelected>
                </UploadDocumentWrapper>
              ))}
              {documents.length < 5 && (
                <AddDocumentBox>
                  <input
                    accept=".png, .jpg, .pdf, .doc, .docx"
                    style={{ display: 'none' }}
                    id={fileInputId}
                    type="file"
                    data-test-id="fileInputTestID"
                    onChange={(event) => this.handleFileChangeDoc(event, category)}
                  />
                  <label htmlFor={fileInputId}>
                    <UploadDocumentButton
                      data-test-id="addDocumentTestId"
                      onClick={() => document.getElementById(fileInputId)?.click()}
                    >
                      <SvgIcon component={AddIcon} style={{ color: '#34D399' }} />
                      <DocUpLoadText>{configJSON.documentAddText}</DocUpLoadText>
                    </UploadDocumentButton>
                  </label>
                </AddDocumentBox>
              )}
            </InnerBox>
          </DocumentInnerWrapper>
        </DocumentWrapper>
      );
    };
  
    interface DocumentAttributes {
      document_category: DocumentCategory;
      name: string;
      url: { url: string };
      file_size: string;
    }
  
    interface Document {
      attributes: DocumentAttributes;
      id: string;
    }
  
    const documentsByCategory: Record<DocumentCategory, Document[]> = {
      personal_id: [],
      agent_docs: [],
      business_registration_certificate: []
    };
  
    this.state.allDocumentsData.forEach((document: Document) => {
      documentsByCategory[document.attributes.document_category].push(document);
    });
  
    return (
      <>
        {renderDocumentItems("Add Documents", documentsByCategory.agent_docs, "agent_docs")}
        {renderDocumentItems("Business Registration Certificate", documentsByCategory.business_registration_certificate, "business_registration_certificate")}
        {renderDocumentItems("Personal ID", documentsByCategory.personal_id, "personal_id")}
      </>
    );
  };

  renderBranchDelete = () => {
    return (
      <DeleteModalBox onClose={this.handlBrancheDelete} open={this.state.isBranchDelete}>
        <Card className="cardBox">
          <CardContent className="cardContent">
            <Box className="box">
            <CloseIcon onClick={this.handlBrancheDelete} className="closeIcon"/>
            </Box>
            <Typography className="deleteTxtBtn marginTop30">
              {configJSON.deleteBankAccountTitle}
            </Typography>
            <Typography className="confirmationTxt">
            {configJSON.deleteBankAccountConfirmation} <span className="deleteTxtBtnBranch">{this.state.branchName}</span>?
            </Typography>
            <Box className="deleteBtnBottomBox">

            <Button className="deleteModalInsideBtn" onClick={this.handleDeleteBranch}>
                {configJSON.deleteButtonText}
              </Button>

              <Button className="confirmModalInsideBtn" onClick={this.handlBrancheDelete}>
                {configJSON.goBackBtnText}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DeleteModalBox>
    );
  };

  showErrorToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="colored"
        transition={Bounce}
      />
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        {this.showErrorToast()}
        <Loader loading={this.state.isLoader} />

        <AppHeader
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          userName={this.state.userDetails?.attributes?.first_name}
          onSignUpClick={undefined}
          toggleData={this.state.toggleDrawer}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          isUserLogin={true}
          isLogin={false}
          role={this.state.userDetails?.attributes?.role}
          profileImg={this.state.userDetails?.attributes?.image}
        />
        <SideMenuBar data-test-id="sidebarNavigationBtn"
          navigationToAnyPage={this.navigationToAnyPage} activeComponent="CustomisableUserProfiles" />
        <Box>
          <MainGrid>
            {this.renderProfileGrid()}
            {this.state.selected === "Account"
              ?
              <>
                {this.renderInfoForm()}
                {this.renderSecurityForm()}
                {this.renderNotification()}
                {this.renderActivityTable()}
              </> :
              <Box>
                {this.rednerTab()}
              </Box>
            }
          </MainGrid>
          {this.renderDelete()}
          {this.renderBranchDelete()}
          {this.state.isModal && this.renderBankModal()}
          {this.state.branchModal && this.renderBranchModal()}
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={1000}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
          >
            <Alert severity={this.state.alertType} data-test-id="alertsTestId">{this.state.alertMsg}</Alert>
          </Snackbar>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// for documents
const PrimaryWrapper = styled(Box)({
  display:"flex",
  gap:'6px'
})

const PrimaryText = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color:"#0F172A"
})

const DocUpLoadText = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16px",
  textAlign: "left",
  color: "#34D399",
  "@media (max-width: 600px)": {
    fontSize: "11px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "13px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px",
  },
})

const UploadDocumentButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid #23CF93",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    height: "auto",
    margin: "8px 0",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    margin: "8px 0",
  },
  "@media (min-width: 769px)": {
    height: "32px",
    margin: "0",
  },
})

const InnerBox = styled(Box)({
  display: "flex",
  margin: "16px 0",
  gap: "16px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    flexDirection: "column",
    margin: "12px 0",
  },
  "@media (min-width: 769px)": {
    flexDirection: "row",
  }
})

const AddDocumentBox = styled(Box)({
  width: "196px",
  height: "172px",
  padding: "16px",
  gap: "16px",
  borderRadius: "8px",
  border: "2px dotted #CBD5E1",
  display:"flex",
  alignItems:"center",
  "@media (max-width: 600px)": {
    height: "auto",
    padding: "12px",
    marginBottom: "20px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    padding: "14px",
    marginBottom: "20px",
  },
  "@media (min-width: 769px)": {
    height: "172px",
    padding: "16px",
  },
  justifyContent:'center'
})

const DownloadDocumentButton = styled(Button)({
  width: "100%",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  opacity: "0.8",
  border: "1px solid #64748B",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop:"8px",
  "@media (max-width: 600px)": {
    margin: "8px 0",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    margin: "8px 0",
  },
  "@media (min-width: 769px)": {
    marginTop: "8px",
  },
})

const DocumentSizeTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  paddingTop:"4px",
  "@media (max-width: 600px)": {
    fontSize: "11px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "11px",
  },
  "@media (min-width: 769px)": {
    fontSize: "12px",
  }
})

const DocumentNameTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "13px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px"
  }
})

const DocumentDetails = styled(Box)({
  width: "194px",
  height: "38px",
  padding: "12px 0px",
  gap: "2px",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    width: "100%",
    height: "auto",
  },
  "@media (min-width: 769px)": {
    width: "194px",
    height: "38px"
  }
})

const PDFDocumentBox = styled(Box)({
  width: "42px",
  height: "42px",
  gap: "0px",
  borderRadius: "130px",
  background:"#F8FAFC",
  position:"relative",
  "& .PDFDocument":{
    width: "24px",
    height: "24px",
    top: "8px",
    left: "8px",
    padding: "0px 1.37px",
    position:"absolute"
  }
})

const DeleteDocumentBox = styled(Box)({
  width: "36px",
  height: "36px",
  gap: "0px",
  borderRadius: "130px",
  background:"#FEE2E2",
  position:"relative",
  "& .DeleteDocument":{
    width: "24px",
    height: "24px",
    top: "5px",
    left: "5px",
    padding: "0px 1.37px",
    position:"absolute"
  }
})

const DocumentAndDelete = styled(Box)({
  display:"flex",
  justifyContent:"space-between"
})

const DocumentDetailWrapper = styled(Box)({
  width: "100%",
  height: "92px",
  gap: "12px",
  "@media (max-width: 600px)": {
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
  },
  "@media (min-width: 769px)": {
    height: "92px"
  }
})

const DocumentWrapper = styled(Box)({
  height: "314px",
  gap: "0px",
  borderRadius: "16px",
  background: "#FFFFFF",
  marginBottom:"30px",
  "@media (max-width: 600px)": {
    height: "auto",
    marginBottom: "20px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    marginBottom: "20px",
  },
  "@media (min-width: 769px)": {
    height: "314px",
    marginBottom: "30px",
  }
})

const DocumentInnerWrapper = styled(Box)({
  paddingLeft: "40px",
  paddingTop: "30px",
  "@media (max-width: 600px)": {
    paddingLeft: "10px",
    paddingTop: "20px",
    paddingRight: "10px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingRight: "20px",
  },
  "@media (min-width: 769px)": {
    paddingLeft:"40px",
    paddingTop:"30px",
    paddingRight: "0px",
  }
})

const UploadDocTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  color: "#0F172A",
  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "16px",
  },
  "@media (min-width: 769px)": {
    fontSize: "18px"
  }
})

const DocumentUploadRule = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#212121",
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    fontSize: "12px",
  },
  "@media (min-width: 769px)": {
    fontSize: "14px",
  }
})

const UploadDocumentWrapper = styled(Box)({
  width: "auto",
  height: "172px",
  top: "112px",
  left: "40px",
  gap: "16px",
  "@media (max-width: 600px)": {
    top: "80px",
    left: "20px",
    height: "auto",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    top: "80px",
    left: "20px",
    height: "auto",
  },
  "@media (min-width: 769px)": {
    top: "112px",
    left: "40px",
    height: "172px"
  }
})

const DocumentSelected = styled(Box)({
  width: "196px",
  height: "172px",
  padding: "16px",
  gap: "16px",
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  "@media (max-width: 600px)": {
    height: "auto",
    padding: "14px",
  },
  "@media (min-width: 601px) and (max-width: 768px)": {
    height: "auto",
    padding: "14px",
  },
  "@media (min-width: 769px)": {
    height: "172px",
    padding: "16px",
  }
})
// document end

// manage staff
const BranchCard = styled(Box)({
  width: "100%",
  maxWidth: "423px",
  height: "auto",
  gap: "24px",
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  backgroundColor: "#FFFFFF",
  padding: "16px",
  boxSizing: "border-box",
})

const BranchNameAndDateWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})

const BranchName = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  color: "#000000",
  "@media (min-width: 600px)": {
    fontSize: "20px",
  },
})

const BranchCreatedDate = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  "@media (min-width: 600px)": {
    fontSize: "12px",
  },
})

const BranchDetailsWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  padding: "16px 0",
  boxSizing: "border-box",
})

const DetailRow = styled(Box)({
  width: "100%",
  height: "auto",
  gap: "16px",
  borderBottom: "1px solid #E2E8F0",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    gap: "54px",
  },
})

const DetailLabel = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  color: "#64748B",
  padding: "12px 0",
  width: "100%",
  boxSizing: "border-box",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    width: "124px",
    padding: "6px 0",
  },
})

const DetailValue = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  color: "#0F172A",
  padding: "12px 0",
  width: "100%",
  boxSizing: "border-box",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    width: "244px",
    padding: "6px 0",
  },
})

const ActionButtonWrapper = styled(Box)({
  width: "100%",
  height: "auto",
  gap: "4px",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    gap: "8px",
  },
})

const EditBranchButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  border: "1px solid #34D399",
  "@media (min-width: 600px)": {
    width: "81px",
  },
})

const EditBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#34D399",
  "@media (min-width: 600px)": {
    fontSize: "16px",
  },
})

const DeleteBranchButton = styled(Button)({
  width: "100%",
  height: "32px",
  padding: "6px 10px",
  gap: "6px",
  border: "1px solid #FEE2E2",
  "@media (min-width: 600px)": {
    width: "102px",
  },
})

const DeleteBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#DC2626",
  "@media (min-width: 600px)": {
    fontSize: "16px",
  },
})

const MobileNumberWrapper = styled(Box)({
  display:"flex",
  gap:"24px"
})

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  }
});

const BranchTextLabelsTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Plus Jakarta Sans",
  color: "rgba(51, 65, 85, 1)"
})

const AddBranchButton = styled(Button)({
  marginLeft: "auto",
  width: "100%",
  maxWidth: "155px",
  height: "44px",
  padding: "10px 16px",
  gap: "8px",
  borderRadius: "8px",
  background:"transparent",
  justifyContent:"flex-start",
  "&:hover": {
    background: "#373335",
  },
  "@media (min-width: 600px)": {
    width: "95%",
    background: "#373335",
    "&:hover": {
      background: "#373335",
    },
  },
  "@media (min-width: 600px) and (max-width: 959px)":{
    width: "95%",
    background:"transparent",
    justifyContent:"flex-start",
    "&:hover": {
      background: "#373335",
    },
  },
  "@media (min-width: 960px)": {
    width: "155px",
  },
})

const AddBranchTypography = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
  "@media (min-width: 600px)": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  "@media (min-width: 600px) and (max-width: 959px)":{
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  }
})

// manage staff end

const ToggleContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "50px",
  overflow: "hidden",
  height: "40px",
  width: "170px",
  "& .selectedClass": {
    borderRadius: "50px",
    padding: "15px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#D6F6EB",
    color: "#000",
    fontWeight: "bold",
    transition: "background-color 0.3s, color 0.3s, font-weight 0.3s"
  },
  "& .nonSelectedClass": {
    borderRadius: "50px",
    padding: "15px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    color: "#000",
    fontWeight: "normal",
    transition: "background-color 0.3s, color 0.3s, font-weight 0.3s"
  }
});

const ProgressBox = styled(Box)({
  display: "flex",
  backgroundColor: "#FFFFFF",
  maxWidth: "600px",
  padding: "15px",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  "@media (max-width: 1200px)": {
    maxWidth: "500px",
    gap: "15px",
  },
  "@media (max-width: 992px)": {
    maxWidth: "320px",
    gap: "10px",
  },
  "@media (max-width: 768px)": {
    maxWidth: "500px",
    gap: "8px",
  },
  "@media (max-width: 576px)": {
    maxWidth: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  "& .linearBox": {
    width: "100%",
    "& .MuiLinearProgress-colorPrimary": {
      borderRadius: "20px",
      backgroundColor: "#D9D9D9",
      height: "10px"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#34D399"
    }
  },

  "& .sendBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "normal",
    backgroundColor: "#F1F5F9",
    borderRadius: "5px",
    padding: "5px !important",
    width: "280px",
    "@media (max-width: 1200px)": {
      width: "220px",
    },
    "@media (max-width: 768px)": {
      width: "200px",
    },
    "@media (max-width: 576px)": {
      width: "100%",
    },
  },
  "& .percantageTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#2A0066",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "bold"
  }
});


const MainGrid = styled(Grid)({
  padding: "50px",
  display: "flex",
  flexDirection: "column",
  gap: "30px !important",
  backgroundColor: "#F7F7F7",
  marginLeft: "64px",
  "& .headerBox": {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "center"
    }
  },

  "& .headingBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "center"
    }
  },

  "& .toggleContainer": {
    padding: "4px",
    backgroundColor: "#ffff",
    border: "1px solid #34D399",
    borderRadius: "50px",
    height: "40px",
    width: "170px",

  },

  "& .heading": {
    fontWeight: 700,
    fontSize: "22px !important",
    lineHeight: "27px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },

  "& .profileBox": {
    paddingTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: "30px"
  },

  "& .imageBox": {
    width: "136px",
    height: "136px",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.3)"
  },

  "& .userImage": {
    width: "100%",
    height: "100%"
  },
  "& .imageInputBtn": { display: "none" },
  "& .editIcon": {
    borderRadius: "50%",
    border: "5px solid white",
    cursor: "pointer",
    position: "relative",
    bottom: 20,
    left: 115,
    backgroundColor: "rgba(228, 230, 231, 1)"
  },

  "& .textBox": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .detailsIcon": {
    height: "15px",
    width: "15px",
    margin: "0px 5px 0px 0px"
  },
  "& .detailsTxt": {
    fontSize: "11px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
  },
  "& .detailsTxtNew": {
    fontSize: "11px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
  },
  "& .innerBox": {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px 0px 0px"
  },
  "& .userDetailsInnerMainBox": {
    display: "flex"
  },
  "& .userName": {
    fontWeight: 700,
    fontSize: "22px !important",
    lineHeight: "27px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .userValue": {
    fontWeight: 400,
    fontSize: "12px !important",
    lineHeight: "15px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .userKey": {
    fontWeight: 600,
    fontSize: "12px !important",
    lineHeight: "15px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)",
    margin: "0px 5px 0px 0px"
  },

  "& .personalInfo": {
    padding: "30px 0",
    gap: "20px 0px",
    display: "flex",
    "@media (max-width: 800px)": {
      padding: "15px"
    }
  },

  "& .infoFormField": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "10px"
  },

  "& .formFieldBox": {
    width: "350px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  },

  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%"
  },

  "& .inputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },

  "& .inputFieldCalender": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    padding: "0px 10px",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }, "& .calendarIcon": {
      color: "#000"
    }
  },

  "& .calenderBox": {
    position: "absolute",
    zIndex: 50
  },

  "& .react-datepicker__input-container": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px"
  },

  "& .dateclass": {
    border: "0px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    width: "100%",
    padding: "0px 10px"
  },

  "& .selectField": {
    backgroundColor: "#fff",
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid #CBD5E1",
    padding: "0px 10px",
    height: "50px",
    margin: "5px 0px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      fontSize: "15px"
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&::before": {
        content: "none"
      },
      "&:focus": {
        outline: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    }
  },

  "& .fieldText": {
    fontWeight: 700,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(51, 65, 85, 1)"
  },

  "& .btnGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .saveInfo": {
    width: "263px",
    height: "54px",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    backgroundColor: "rgba(52, 211, 153, 1)",
    color: "white",
    textTransform: "none"
  },

  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },

  "& .securityInputField": {
    backgroundColor: "#fff",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },

  "& .emailPrefrencesGrid": {
    width: "100%"

  },

  "& .emailPrefrencesBox": {
    border: "1px solid rgba(203, 213, 225, 1)",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    alignSelf: "center",
    backgroundColor: "#fff"
  },

  "& .emailPrefrencesText": {
    fontWeight: 700,
    fontSize: "32px !important",
    lineHeight: "40px !important",
    fontFamily: "Plus Jakarta Sans !important"
  },

  "@media screen and (min-width: 600px) and (max-width: 959px)": {
    "& .securityFormField": {
      justifyContent: "center"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 599px)": {
    "& .emailPrefrencesText": {
      fontSize: "22px !important",
      lineHeight: "30px !important"
    },
    padding: "20px",
    "& .infoFormField": {
      justifyContent: "center"
    },

    "& .profileBox": {
      flexDirection: "column"
    }
  },
});

const SelectInput = styled(Select)({
  "@media (max-width: 600px)": {
    fontSize: "15px"
  },
  backgroundColor: "#fff",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
});

const TableMainBox = styled(Box)({
  "& .MuiTableCell-head": {
    color: "#000",
    fontWeight: "600",
    fontSize: "14px"
  },
  "& .MuiTableContainer-root": {
    margin: "30px 0px"
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    borderRadius: "8px"
  }
});

const NotificationBox = styled(Box)({
  "& .maarginBottom": { marginBottom: "30px" },
  "& .emailTxt": {
    color: "#2A0066",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: "700"
  },
  "& .normalTxt": {
    color: "#0F172A",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: "400"
  },
  "& .imageTxtBox": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .alllowAllBox": {
    backgroundColor: "#F8FAFC",
    height: "60px",
    alignItems: "center",
    display: "flex", justifyContent: "space-between",
    padding: "20px",
    borderBottom: "1px solid #E2E8F0"
  },
  "& .emailImg": {
    height: "30px",
    width: "30px"
  },
  "& .gridBox": {
    height: "70px",
    alignItems: "center",
    padding: "20px",
    display: "flex", justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0"
  }
});

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none"
      },
      "& .MuiSwitch-thumb": {
        color: "#fff",
        border: "6px solid #fff"
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  },
}));


const WhatsAppBox = styled(Box)({
  "& .insideBox": {
    padding: "0px",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "5px"
  },
  "& .comingSoonTxt": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "18px",
    fontWeight: "700"
  },
  "& .comingSoonDescTxt": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: "400"
  }
});

const MainTabBox = styled(Box)({
  margin: "30px 0px 0px 0px",
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff"
  },
  "& .MuiTab-wrapper": {
    color: "#000"
  },
  "& .MuiBox-root-18": { padding: "24px 0px" },

  "& .MuiTabs-indicator ": {
    backgroundColor: "#34D399",
    height: "5px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px"
  },
  "& .Styled(MuiBox)-root-3 .MuiTab-wrapper ": {
    color: "blue !important",
    fontSize: "50px"
  },
  "& .MuiPaper-elevation4": {
    boxShadow: "none",
    borderBottom: "2px solid #E2E8F0"
  },
  "& MuiBox-root-18": {
    backgroundColor: "#fff"
  },
  "& .tabLabel": {
    textTransform: "unset",
    fontSize: "15px"
  },
  "& .SvgIconAddBranch": {
    color:"#000",
    "@media (min-width: 600px)": {
      color: "#fff",
    },
    "@media (min-width: 600px) and (max-width: 959px)":{
      color:"#000",
    }
  },
  "& .MuiButton-root": {
    textTransform:"none"
  }
});

const TabBox = styled(Box)({
  padding: "24px 0px"
}

);

const StyledBankCard = styled(Box)({
  "& .MuiCardContent-root": {
    padding: "0px !important",
    minHeight:"400px",
    display:'flex',
    flexDirection:"column",
    justifyContent:"space-between"
  },
  "& .bankHeadingTxt": {
    width: "200px",
    marginLeft: "10px",
    fontWeight: 700,
    fontSize: "18px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#B2363A",
    padding: "10px",
    "@media (max-width: 800px)": {
      padding: "0px"
    }
  },
  "& .headingBoldTxt": {
    marginLeft: "10px",
    width: "200px",
    minWidht:"200px",
    fontWeight: 700,
    fontSize: "14px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding: "10px",
    "@media (max-width: 800px)": {
      padding: "0px"
    }
  },
  "& .detailsTxt": {
    fontWeight: 400,
    fontSize: "14px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding: "10px"
  },
  "& .rowBox": {
    display: "flex",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    },
    "& .addBank": {
      height: "100%",
      backgroundColor: "red"
    },
    "& .detailsTxt":{
      minWidth: "300px",
      maxWidth: "400px"
    }
  },
  "& .cardContentBox": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .addBankBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#34D399",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "normal",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px !important",
    width: "250px",
    border: "1px solid #34D399",
    height: "40px"
  },
  "& .editDeleteBtnBox": {
    borderTop: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    padding: "20px !important",
    gap: "10px",
    justifyContent:"space-between"
  },
  "& .editBtn": {
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px 10px",
    color: "#34D399",
    border: "1px solid #34D399"
  },
  "& .deleteBtn": {
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "5px 10px",
    color: "#DC2626",
    border: "1px solid #DC2626",
  },
});

const DeleteModalBox = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .cardBox": {
    maxWidth: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
  },
  "& .cardContent": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  "& .box": {
    position: "relative"
  },
  "& .closeIcon": {
    position: "absolute",
    right: "20px",
    top: "20px"
  },
  "& .deleteTxtBtn": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
    padding:"0 16px"
  },
  "& .deleteTxtBtnBranch": {
    fontWeight: 700,
    fontSize: "20px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#212121",
  },
  "& .marginTop30":{marginTop: "30px"},
  "& .confirmationTxt": {
    color: "#212121",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "18px",
    fontWeight: "400",
    padding:"0 16px",
    paddingBottom:"24px"
  },
  "& .deleteBtnBottomBox": {
    width: "400px",
    height: "80px",
    padding: "24px 0px 0px 0px",
    gap: "16px",
    borderRadius: "10px",
    borderTop: "1px solid #E2E8F0",
    background: "#FFFFFF",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center"
  },
  "& .deleteModalInsideBtn": {
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #BB393D",
    boxShadow: "0px 4px 4px 0px #00000040",
    color:"#BB393D"
  },
  "& .confirmModalInsideBtn":{
    width: "91px",
    height: "32px",
    padding: "6px 10px",
    gap: "6px",
    borderRadius: "8px",
    background: "#BB393D",
    color: "#FFFFFF",
    marginRight:"24px"
  }
});

const MainModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
  "& .paper": {
    overflowY: "scroll",
    width: "480px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    padding: "30px 35px",
    gap: "20px",
    position:"relative",
    height:"auto"
  },
  "& .MangeStaff-paper":{
    overflowY: "scroll",
    borderRadius: "10px",
    display: "flex",
    padding: "30px 35px",
    gap: "20px",
    width: "480px",
    position:"relative",
    height:"auto"
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)",
    textAlign: "center"
  },
  "& .formFieldBox": {
    width: "100%"
  },
  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom:"10px"
  },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .fieldText": {
    fontWeight: 700,
    fontSize: "20px !important",
    lineHeight: "28px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)"
  },
  "& .errorText": {
    color: "red"
  },
  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .buttonBox": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingTop:"20px"
  },
  "& .buttonBoxBranch": {
    display:"flex",
    justifyContent: "center",
    paddingTop: "34px",
  }, 
  "& .cancelBtn": {
    color: "rgba(52, 211, 153, 1)",
    background: "rgba(214, 246, 235, 1)",
    borderRadius: "8px",
    width: "45%",
    border: "1px solid rgba(52, 211, 153, 1)",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      background: "rgba(214, 246, 235, 1)",
    }
  },
  "& .addBtn": {
    background: "rgba(52, 211, 153, 1)",
    borderRadius: "8px",
    width: "45%",
    textTransform: "none",
    color: "white",
    "&.MuiButton-root:hover": {
      background: "rgba(52, 211, 153, 1)"
    }
  },
});

const UpdatedMainModelStyle = styled(Paper)({
  "& .HeadingContainer" : {
    display:"flex",
    justifyContent:"flex-start",
    marginBottom: "20px"
  },
  "& .NewHeading":{
    fontWeight: 700,
    fontSize: "24px !important",
    fontFamily: "Plus Jakarta Sans !important",
    color: "rgba(33, 33, 33, 1)",
  },
  "& .MangeStaffMobileContainer": {
    width: "100%"
  }
})
// added for Aggreement screen
// Breakpoints
const breakpoints = {
  mobile: "600px",
  tablet: "900px",
  desktop: "1200px",
  largeDesktop: "1440px",
};

const TermsAndServiceWrapper = styled(Box)(({ theme }) => ({
  height: "auto",
  borderRadius: "16px",
  backgroundColor: "#FFFFFF",
  padding: "50px",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "30px",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
    lineHeight: "24px",
    width:"50%"
  },
}));

const InstructionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    lineHeight: "16px",
    width:"50%"
  },
}));

const AgreementContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "24px 0",
  position: "relative",
  zIndex: 5,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    marginRight: "16px"
  },
}));

const DownloadAgreementButton = styled(Box)(({ theme }) => ({
  width: "330px",
  height: "98px",
  padding: "16px 0px 0px 0px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #E2E8F0",
  background: "#F1F5F9",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
    marginRight: "16px",
  },
}));

const DownloadDocumentText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const DownloadFileButton = styled(Button)(({ theme }) => ({
  width: "298px",
  padding: "6px 10px",
  gap: "6px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #64748B",
  margin: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "8px 0",
  },
}));

const UploadFileButton = styled(Button)(({ theme }) => ({
  width: "298px",
  height: "32px",
  padding: "6px 10px 6px 10px",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid #23CF93",
  margin: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    margin: "8px 0",
  },
}));

const DownLoadText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const UpLoadText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#34D399",
  [theme.breakpoints.down("sm")]: {
    fontSize: "11px",
  },
}));

const ReadItThrough = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "52px",
  padding: "16px 0px 0px 0px",
  gap: "16px",
  borderRadius: "8px 0px 0px 0px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px 0",
  },
}));

const ReadItTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const DoneTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const ReadWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
    margin: "20px 0px 20px 150px"
  },
}));

const DoneWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
    margin: "20px 0px 20px 150px"
  },
}));

const BackgroundLine = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "100%",
  height: "10px",
  backgroundColor: "#F1F5F9",
  position: "absolute",
  left: "0",
  transform: "translateY(-55%)",
  zIndex: -1, // Add z-index here
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px",
    height: "100%",
    backgroundColor: "#F1F5F9",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1, // Add z-index for small screens as well
  },
}));


const BackgroundLineSuccess = styled(Box)(({ theme }) => ({
  top: "60%",
  width: "100%",
  height: "10px",
  backgroundColor: "#34D399",
  position: "absolute",
  left: "0",
  zIndex: -1,
  transform: "translateY(-55%)",
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#34D399",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const BackgroundLineFailure = styled(Box)(({ theme }) => ({
  top: "50%",
  width: "100%",
  height: "10px",
  backgroundColor: "#FEE2E2",
  position: "absolute",
  left: "0",
  transform: "translateY(-50%)",
  zIndex: -1,
  [theme.breakpoints.down("sm")]: {
    top: "0",
    bottom: "0",
    width: "10px", 
    height: "100%",
    backgroundColor: "#FEE2E2",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
}));

const FileUploaded = styled(Box)(({ theme }) => ({
  width: "330px",
  height: "98px",
  padding: "8px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #F8FAFC",
  backgroundColor: "#E2E8F0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop:'-17px',
  marginLeft:"-1px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
    marginTop:'-15px',
    marginLeft:"-1px"
  },
}));

const FileUploadedError = styled(Box)(({ theme }) => ({
  width: "311px",
  height: "94px",
  padding: "8px",
  gap: "16px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #F87171",
  backgroundColor: "#E2E8F0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop:"-15px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "8px",
  },
}));

const FileNameTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const FilesizeTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#64748B",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const DownloadIconButton = styled(Button)(({ theme }) => ({
  width: "auto",
  height: "auto",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "#64748B",
  padding: "0px",
}));

const DeleteIconButton = styled(Button)(({ theme }) => ({
  width: "auto",
  height: "auto",
  gap: "6px",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "#DC2626",
  padding: "0px",
}));

const ActionButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "4px",
  },
}));

const FileUploadErrorText = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: "#BB393D",
  padding: "5px",
  width:"100%",
  [theme.breakpoints.down("sm")]: {
    fontSize: "8px",
    padding: "8px",
    width:"45%",
  },
}));

const FixedRequiredText = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "center",
  color: "#DC2626",
  padding:'5px',
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const TablePaper = styled(Paper)({
  width: "100%",
  overflowX: "auto",
  "& .table": {
    minWidth: 650,
  },
  "& .tableCellCentered": {
    display: "flex",
    alignItems: "center",
  },
  "& .borderedSpan":{
    border:"2px solid #ECECEE",
    whiteSpace: "nowrap",
    marginRight:"2px",
    marginLeft: "2px"
  },
  "& .statusActive": {
    background: "#CFFBE6",
    color: "#3EAA86",
    padding: "8px",
    borderRadius: "10px",
    textTransform:"uppercase",
    fontWeight:"bold",
    fontSize:"12px"
  },
  "& .statusInactive": {
    background: "#FFE1DF",
    color: "#DB5460",
    padding: "8px",
    borderRadius: "10px",
    textTransform:"uppercase",
    fontWeight:"bold",
    fontSize:"12px"
  },
  "& .MuiTableCell-head":{
    fontWeight:600
  },
  "& .MuiTableCell-root": {
    textAlign: "center",
    justifyContent: "center"
  },
  "& .tableCellExpanded": {
    background: "#F9FAFC",
  },
  "& .expandMore": {
    verticalAlign: "middle",
  },
});

const ExpandedBox = styled(Box)({
  display: "flex",
  gap: "20px",
  flexWrap: "wrap",
  padding: "0px 0px 20px 0px",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
  "& .expandedContent": {
    padding: "0 0 10px 0",
    background: "#F9FAFC",
    width:"300px"
  },
  "& .boxTop": {
    padding: "10px",
    display: "flex",
    gap: "20px",
    background: "#fff",
    alignItems: "center",
  },
  "& .topLeft": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  "& .appIdBox": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "& div": {
      fontSize: "12px",
    },
  },

  "& .viewAppBtn": {
    border: "1px solid #10b981",
    color: "#10b981",
    height: "30px",
    fontWeight: "bold",
    textTransform: "none",
  },
  "& .statusInprogress": {
    backgroundColor: "#fef3c7",
    color: "#b45309",
    fontWeight: "500",
    fontSize: "0.875rem",
    padding: "2px 15px",
    borderRadius: "10px",
    textTransform: "uppercase",
  },
  "& .middleBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#F9FAFC",
  },
  "& .assignedTo": {
    fontSize: "12px",
  },
  "& .reasignBtn": {
    color: "#10b981",
    fontWeight: "bold",
  },
  "& .boxBottom": {
    padding: "0px 20px",
    textAlign:"start"
  },
  "& .boxBottomInner": {
    border: "1px solid #bbb",
    display: "flex",
    gap: "20px",
    padding: "0px 15px",
    borderRadius: "10px",
    backgroundColor: "#F9FAFC",
    lineHeight: 0.6,
  },
  "& .bottomLeft": {
    "& p":{
      lineHeight:1
    }
  },
  "& .bottomRight": {},
  "& p": {
    fontSize: "12px",
  },
  "& .textBold": {
    fontWeight: "bold",
    whiteSpace: "normal",
    wordBreak: 'break-word',     
    overflowWrap: 'anywhere',  
    lineHeight:1  
  },
});

const CustomizeRadio = styled(Radio)({
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
            {
                color: '#34D399',
                width:24,
                height:24
            },
        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
            color: '#34D399',
            width:24,
            height:24
        },
})

function RadioButton(props: RadioProps) {
  return (
    <CustomizeRadio
      disableRipple
      color="default"
      checkedIcon={<CheckCircle />}
      {...props}
    />
  );
}

const ReassignActions = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 10px 0 10px",
  "& .reassignLeft": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .reassignToAllBtn": {
    background: "#000",
    color: "#fff",
    textTransform: "none",
    padding: "2px 10px",
  },
  "& .reassignRight": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .iconWarning": {
    color: "#CAB052",
    fontSize: "20px",
    paddingTop: "2px",
  },
  "& .reassignWarnText": {
    color: "#5D506F",
  },
  "& .deactivateBtn": {
    background: "#F1F4F9",
    color: "#717987",
    border: "1px solid #717987",
    padding: "2px 10px",
    textTransform: "none",
  },
});


// Aggreement screen ends
// Customizable Area End
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  isPasswordShow: boolean;
  isConfirmPasswordShow: boolean;
  loading: boolean;
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: {
        url: string;
      },
    },
  },
  CardDetailsData: Array<{
    id: string | number,
    heading: string,
    points: string,
    imageUrl: string,
    PublishDate: string,
    AnnouncementType: string,
    ContryName: Array<string>
  }>,
  page: number,
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  stateDate:string,
  endDate:string,
  todayDate:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start
interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}

interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

// Customizable Area End

export default class PasswordResetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  getLatestUpdateAPICallId: string = "";
  filterLatestUpdatedByDateApiId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      password: "",
      confirmPassword: "",
      isPasswordShow: false,
      isConfirmPasswordShow: false,
      loading: false,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      stateDate:"",
      endDate:"",
      todayDate:new Date().toISOString().split('T')[0]
    };
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProfileAPICallId) {
          this.getProfileFormSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.getLatestUpdateAPICallId) {
          this.SetLatestUpdateData(responseJson)
        }
        if(apiRequestCallId === this.filterLatestUpdatedByDateApiId){
          this.SetLatestUpdateData(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("token")
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
      this.getAllLatestUpdateApiCallFuncation()
    })
  }

  SetLatestUpdateData = (responseJson: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        announcement_type: string;
        title: string;
        content: string;
        published_at: string;
        image: string;
        country: [];
      }
    }>
  }) => {
    const ManipulateData = responseJson.data.map((item) => {
      return {
        id: item.id,
        heading: item.attributes.title,
        points: item.attributes.content,
        imageUrl: item.attributes.image,
        PublishDate: item.attributes.published_at,
        AnnouncementType: item.attributes.announcement_type,
        ContryName: item.attributes.country
      }
    })
    this.setState({
      CardDetailsData: ManipulateData
    })
  }

  SetInnteHtml = (htmlString: string) => {
    return {
      __html: htmlString
    };
  }

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  formApprovalApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.formApprovalApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  getAllLatestUpdateApiCallFuncation = async () => {
    this.getLatestUpdateAPICallId = await this.formApprovalApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getrecentUpdateEndPoint
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  renderCount = () => {
    return Math.ceil(this.state.CardDetailsData.length / 4)
  };
  handleChangePage = (event: { type: string }, newPage: number) => {
    this.setState({ page: newPage, valueKey: event?.type });
  };
  paginateData = (data: Array<{
    id: string | number,
    heading: string,
    points: string,
    imageUrl: string,
    PublishDate: string,
    AnnouncementType: string,
    ContryName: Array<string>
  }>, page: number, rowsPerPage: number) => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  handleOpenClose = (value?: string) => {
    this.setState({
      updateModalOpen: !this.state.updateModalOpen,
      updateValue: `${value}`,
    });
  };

  handleStartDateChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      stateDate:event.target.value,
      endDate:""
    })
  }
  handleEndDateChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      endDate:event.target.value
    },()=>{
      if(this.state.stateDate&&this.state.endDate){
        this.getLatestUpdateByDate()
      }
    })
  }

  getLatestUpdateByDate = async () => {
    this.filterLatestUpdatedByDateApiId = await this.formApprovalApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.latestUpdateByDateEndPoint}?from=${this.state.stateDate}&to=${this.state.endDate}`
    });
  }
  // Customizable Area End
}
import React from "react";
// Customizable Area Start
import EmailAccountRegistrationController, { Props, configJSON } from "./EmailAccountRegistrationController.web";
import AppHeader from "../../../components/src/AppHeader.web";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  styled
} from "@material-ui/core";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
import { KeyboardArrowDown } from "@material-ui/icons";
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainBox = () => {
    return (
      <MainBox>
        <FirstBox>
          <FirstInnerBox>
            <SignUpTxt>{configJSON.singUpTxt}</SignUpTxt>
            <AgentSelect
              onChange={this.handleRole}
              defaultValue="agent"
              data-test-id="agentTestID"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
              }}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem value="agent">{configJSON.asAgentTxt}</MenuItem>
              <MenuItem value="student">{configJSON.asStudentTxt}</MenuItem>
            </AgentSelect>
          </FirstInnerBox>
          <InputMainBox>
            <InputTxt>{configJSON.companyTxt}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
              data-test-id="companyTestId"
              placeholder={configJSON.companyPlaceholderTxt}
              onChange={this.handleCompanyNameChange}
            />
            {this.state.companyError && (
              <RequiredTxt>{this.state.companyErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <InputMainBox>
            <InputTxt>{configJSON.emailTxt}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
              data-test-id="emailChangeTestId"
              placeholder={configJSON.emailPlaceholderTxt}
              onChange={this.handleEmailChange}
              value={this.state.email}
            />
            {this.state.emailError && (
              <RequiredTxt>{this.state.emailErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <InputMainBox>
            <InputTxt>{configJSON.noEmp}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
              }}
              style={{
                color: this.state.numberOfEmployees == "none" ? "#999" : "#000"
              }}
              value={this.state.numberOfEmployees}
              data-test-id="employeeDataTestId"
              defaultValue="default"
              IconComponent={KeyboardArrowDown}
              onChange={this.handleNumberOfEmployeesChange}
            >
              {this.state.numberOfEmployees == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.selectEmp}
                </MenuItem>
              )}
              {this.state.noEmpData.map((item: { id: string; title: string }) => (
                <MenuItem value={item.title} key={item.id}>{item.title}</MenuItem>
              ))}
            </SelectInput>
            {this.state.numberOfEmployeesError && (
              <RequiredTxt>{this.state.numberOfEmployeesErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <InputMainBox>
            <InputTxt>{configJSON.selectCountry}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
              }}
              style={{
                color: this.state.countryValue == "none" ? "#999" : "#000"
              }}
              value={this.state.countryValue}
              data-test-id="countryDataTestId"
              IconComponent={KeyboardArrowDown}
              onChange={this.handleCountryChange}
            >
              {this.state.countryValue == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.selectCountryPlaceholderTxt}
                </MenuItem>
              )}
              {this.state.countryData.map((item) => (
                <MenuItem value={item.isoCode}>{item.name}</MenuItem>
              ))}
            </SelectInput>
            {this.state.countryError && (
              <RequiredTxt>{this.state.countryErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <InputMainBox>
            <InputTxt>{configJSON.selectState}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <SelectInput
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                disableScrollLock: true,
              }}
              style={{
                color: this.state.stateValue == "none" ? "#999" : "#000"
              }}
              value={this.state.stateValue}
              data-test-id="stateDataTestId"
              IconComponent={KeyboardArrowDown}
              defaultValue="default"
              onChange={this.handleStateChange}
            >
              {(this.state.stateValue) == "none" && (
                <MenuItem disabled value="none">
                  {configJSON.selectStatePlaceholderTxt}
                </MenuItem>
              )}
              {this.state.stateData.map((item: { name: string }) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </SelectInput>
            {this.state.stateError && (
              <RequiredTxt>{this.state.stateErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <InputMainBox>
            <InputTxt>{configJSON.selectCity}
              <StarTxt> {configJSON.starTxt}</StarTxt>
            </InputTxt>
            <InputField
              placeholder={configJSON.cityPlaceholderTxt}
              onChange={this.handleCityChange}
              value={this.state.cityName}
              data-test-id="cityDataTestId"
            />
            {this.state.cityNameError && (
              <RequiredTxt>{this.state.cityNameErrorTxt}</RequiredTxt>
            )}
          </InputMainBox>
          <AgreeMainBox>
            <AgreeBox>
              <CheckboxInput
                checked={this.state.isChecked}
                onChange={this.handleChange}
                name="checkedB"
                data-test-id="checkedBoxTestId"
              />
              <AgreeTxt>{configJSON.agreeTxt}<span className="agreeSpanTxt" data-test-id="privacyPolicyTestId" onClick={this.navigateToPrivacyPolicyPage}>{configJSON.agreePrivacyTxt}</span> {configJSON.andTxt} <span className="agreeSpanTxt" data-test-id="termsandconditionTestId" onClick={this.navigateToTermsConditionPage}>{configJSON.agreeTermsTxt}</span>
              </AgreeTxt>
            </AgreeBox>
            {this.state.isCheckError && (
              <RequiredTxt>{this.state.isCheckErrorTxt}</RequiredTxt>
            )}
          </AgreeMainBox>
          <SignupButton
            data-test-id="signUpBtnTestId"
            onClick={this.onSignupClick}>
            {configJSON.signupTxt}
          </SignupButton>
          <LoginBox>
            <AlreadyTxt>
              {configJSON.alreadyTxt}
            </AlreadyTxt>
            <LoginButton onClick={this.navigateToLoginPage}
              data-test-id="loginBtnTestID"
            >
              {configJSON.loginTxt}
            </LoginButton>
          </LoginBox>
        </FirstBox>
        <SecondBox>
          <SideImg src={IMG_CONST.sideImg} />
          <JoinTxt>{configJSON.JoinTxt} {this.state.role}.</JoinTxt>
          <BelowTxt>{configJSON.belowTxt}</BelowTxt>
          <BelowTxt>{configJSON.belowTxt2}</BelowTxt>
        </SecondBox>
      </MainBox>
    );
  };

  renderPasswordBox = () => {
    return (
      <Box style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "30px 0px"
      }}>
        <PswdMainImg src={IMG_CONST.sideImg} />
        <PswrdJoinTxt>{configJSON.JoinTxt} {this.state.role} .</PswrdJoinTxt>
        <PswrdBelowTxt>{configJSON.thanksTxt}</PswrdBelowTxt>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppHeader
          isUserLogin={false}
          isLogin={false}
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          onSignUpClick={undefined}
          toggleData={this.state.toggleDrawer}
          handleToggle={this.handleToggle}
          data-test-id="appHeaderTestId"
          userName={undefined}
          role={undefined}
        />
        <Snackbar
          autoHideDuration={3000}
          open={this.state.isAlert}
          onClose={this.oncloseAlert}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        {
          this.state.isPswrd
            ?
            this.renderPasswordBox()
            :
            this.renderMainBox()
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  margin: "40px 0px",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px 20px"
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column-reverse",
    alignItems: "center",
    margin: "10px"
  }
}));

const FirstBox = styled(Box)(({ theme }) => ({
  width: "400px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  },
  [theme.breakpoints.down("xs")]: {
    padding: "20px"
  },
}));

const FirstInnerBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0px 0px 25px 0px"
});

const SignUpTxt = styled(Typography)({
  color: "#B6373C",
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Plus Jakarta Sans"
});

const AgentSelect = styled(Select)({
  height: "50px",
  width: "165px",
  padding: "0px 10px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const InputMainBox = styled(Box)({
  margin: "0px 0px 20px 0px"
});

const InputTxt = styled(Typography)({
  color: "#334155",
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Plus Jakarta Sans"
});

const StarTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans"
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
})

const InputField = styled(TextField)({
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});

const SelectInput = styled(Select)({
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const SideImg = styled("img")({
  height: "450px",
  width: "450px",
  "@media (max-width: 768px)": {
    height: "300px",
    width: "300px"
  },
  "@media (max-width: 600px)": {
    height: "200px",
    width: "200px"
  }
});

const PswdMainImg = styled("img")({
  height: "350px",
  width: "350px",
  "@media (max-width: 768px)": {
    height: "300px",
    width: "300px"
  },
  "@media (max-width: 600px)": {
    height: "200px",
    width: "200px"
  }
});

const JoinTxt = styled(Typography)({
  color: "#000",
  fontSize: "36px",
  fontWeight: 700,
  margin: "20px 0px",
  fontFamily: "Plus Jakarta Sans",
  textAlign: "center",
  "@media (max-width: 768px)": {
    fontSize: "28px"
  },
  "@media (max-width: 600px)": {
    fontSize: "24px"
  }
});

const PswrdJoinTxt = styled(Typography)({
  color: "#000",
  fontSize: "36px",
  fontWeight: 700,
  margin: "20px 0px",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width: 768px)": {
    fontSize: "28px"
  },
  "@media (max-width: 600px)": {
    fontSize: "24px"
  }
});

const BelowTxt = styled(Typography)({
  fontSize: "18px",
  color: "#475569",
  width: "400px",
  textAlign: "center",
  margin: "0px 0px 10px 0px",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width: 768px)": {
    fontSize: "16px",
    width: "300px"
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
    width: "200px"
  }
});

const PswrdBelowTxt = styled(Typography)({
  fontSize: "16px",
  color: "#475569",
  width: "400px",
  textAlign: "center",
  margin: "0px 0px 10px 0px",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width: 768px)": {
    fontSize: "16px",
    width: "300px"
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
    width: "200px"
  }
});

const LoginBox = styled(Box)({
  display: "flex",
  margin: "30px 0px",
  alignItems: "center"
});

const AlreadyTxt = styled(Typography)({
  color: "#0F172A",
  fontSize: "15px",
  fontFamily: "Plus Jakarta Sans"
});

const AgreeBox = styled(Box)({
  display: "flex",
  alignItems: "center"
});

const AgreeMainBox = styled(Box)({
  margin: "30px 0px 20px 0px"
});

const AgreeTxt = styled(Typography)({
  fontSize: "14px",
  color: "#0F172A",
  fontWeight: 400,
  lineHeight: "20px",
  fontFamily: "Plus Jakarta Sans",
  "& .agreeSpanTxt": {
    cursor: "pointer", color: "#BB393D",
    fontSize: "14px"
  }
});

const SignupButton = styled(Button)({
  width: "100%",
  backgroundColor: "#BB393D",
  color: "#fff",
  fontSize: "14px",
  textTransform: "unset",
  fontFamily: "Plus Jakarta Sans",
  height: "50px",
  "&.MuiButton-root:hover": {
    backgroundColor: "#BB393D"
  }
});

const LoginButton = styled(Button)({
  color: "#BB393D",
  fontSize: "14px",
  height: "20px",
  fontWeight: 700,
  textTransform: "unset",
  fontFamily: "Plus Jakarta Sans"
});

const SecondBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column"
});

const CheckboxInput = styled(Checkbox)({
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#BB393D"
  }
});

// Customizable Area End
